.popup {
    width: 330px;

    :global {
        .bp3-inline .bp3-label {
            min-width: 100px;
        }

        .bp3-form-content {
            width: 100%;
        }
    }
}

@import "../variables.scss";

.slider {
    display: flex;
    margin-right: 320px;
    align-items: center;

    .value {
        cursor: pointer;
        font-size: 18px;
        color: $standard-planr-darkblue;
        font-weight: 400;
    }

    :global {
        .month-picker {
            display: inline-block;
        }
    }
}

.step {
    cursor: pointer;

    &:hover {
        color: $standard-orange;
    }
}

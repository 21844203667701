@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

.financeHeader {
    color: $standard-planr-darkblue;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    margin-top: 16px;
    display: flex;

    :global {
        .bp3-popover-wrapper {
            margin-left: auto;
        }
    }
}

.column {
    position: relative;
    // inputs outline
    width: 200px;

    & + .column {
        margin-left: 20px;
    }
}

.closedFields {
    height: 0px;
    visibility: hidden;
    opacity: 0;
    transition: height 0.2s;
}
.openedFields {
    height: 150px;
    visibility: visible;
    opacity: 1;
    transition: height 0.2s;
}
.openedFieldsAct {
    height: 238px;
    visibility: visible;
    opacity: 1;
    transition: height 0.2s;
}
.fieldsLabel {
    color: $standard-planr-darkblue;
    font-size: 12px;
    margin-bottom: 3px;
}
.dashboardRow {
    margin-bottom: 16px;
    border: 2px solid $standard-planr-gray-color;
    border-radius: 4px;
    padding: 8px 10px;

    hr {
        border: none;
        height: 2px;
        color: $standard-planr-gray-color;
        background-color: $standard-planr-gray-color;
    }

    .dashboardLabel {
        color: $standard-planr-darkblue;
        font-size: 13px;
        margin-bottom: 10px;
        position: relative;

        .dashboardIcon {
            position: absolute;
            color: #ea561e;
            right: 5px;
            cursor: pointer;
        }
    }
    .dashboard {
        display: flex;
        width: 100%;
        height: 35px;
    }
}

.overheads,
.productions,
.premiums,
.profitability {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;

    :global {
        .bp3-popover-target {
            width: 100%;
            height: 100%;
        }
    }
}
.overheads {
    background-color: $planr-lightblue-color;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.productions {
    background-color: #fbb916;
}
.premiums {
    background-color: #1dd278;
}
.profitability {
    background-color: #d76c36;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.legend {
    width: 100%;
    color: $standard-planr-darkblue;
    display: flex;

    .legendColumn {
        .legendRow {
            display: flex;
            margin-bottom: 8px;
            font-size: 12px;
            margin-left: 24px;

            .round {
                width: 20px;
                height: 20px;
                border-radius: 10px;
                margin-right: 10px;
            }
        }
        .legendRow + .legendRow {
            margin-right: 30px;
        }
    }
}

.fieldsInputs {
    display: flex;
    transition-delay: 0.2s;
}

.percentsValue {
    @include planr-font("small");
    color: white;
    position: absolute;
    z-index: 10;
}
.sliceHelper {
    display: block;
    width: 100%;
    height: 100%;
}

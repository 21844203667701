@import "modules/spending/timesheet/components/_common.scss";
@import "./_common";
@import "./UnitUserDashboard.module.scss";

$offcet: 80px;

.page {
    position: relative;
    width: 98%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0 $standard-padding 0 $standard-padding;
    background-color: $light-planr-gray;
    :global {
        .bp3-form-group {
            margin-bottom: 0;
        }

        .add-button {
            margin-top: -60px;
            margin-right: 16px;
            z-index: 1;
            border-radius: 120px;
        }

        .buttons {
            margin-bottom: $standard-padding;
        }

        .user-filter {
            display: inline-block;
            margin-left: $standard-padding;
        }
    }
}
.filterBtns {
    position: absolute;
    right: 24px;
    top: 60px;
    display: flex;
}

.yearItem {
    width: 100px;
    padding: 7px 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    transition: 0.2s ease-in;
    color: $standard-planr-darkblue;
    cursor: pointer;

    &:hover {
        background-color: #f5e8e3;
    }
}

.yearActive {
    width: 100px;
    padding: 7px 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    transition: 0.2s ease-in;
    color: #fff;
    cursor: pointer;
    background-color: #ea561e;
}
.rowControl {
    transform-origin: top left;
    align-items: flex-start;
    align-self: flex-start;
    width: 100%;
    overflow-y: scroll;
    display: flex;

    :global {
        .department-block:first-of-type .block-header {
            margin-top: 0;
        }

        .schedule-user-row .schedule-user-name {
            background-color: $white;
        }
    }
}
.tabsTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .lowTitle {
        font-size: 10px;
    }
}
.filters {
    margin-top: $standard-padding;
    display: flex;
    flex-direction: row;
    position: relative;

    .tools {
        display: flex;
        min-height: $control-height;
        left: 16px;
        align-items: center;
        position: absolute;
    }
    .right {
        position: absolute;
        right: 16px;
        display: flex;
        align-items: center;
        height: 50px;
    }
}

.totalLine {
    display: flex;
    flex-direction: column;
    top: 0px;
    position: absolute;
}

.nodata {
    margin: auto;
}

.zoomer {
    margin-left: $offcet;
    display: inline-block;
}

.rightData {
    display: flex;
    margin-left: 16px;
    width: 100%;
}

.grid {
    display: flex;
    flex: 1 1;
    margin-top: -30px;
    flex-direction: column;
}

.departmentSubtitle {
    font-size: 12px;
    color: rgba(0, 39, 61, 0.4);
}

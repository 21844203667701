@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

.planPayment {
    display: flex;
    flex-direction: row;

    &.consignment {
        flex-direction: column;
    }

    & > div {
        padding: 5px;
    }
}

.paymentSum,
.paymentNumber {
    padding: 5px;
}

.paymentSum {
    width: 140px;
    text-align: center;
    padding-bottom: 0;
}

.paymentNumber {
    width: 80px;
    padding-bottom: 0;
    font-weight: 300;
}

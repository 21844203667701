@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";

$name: 340px;

$cell: 46px;
$border: 1px solid #bebebe;

$weekend: #e4e8eb;
$weekend-color: #fff;
$font-size: $font-size-small;
$missing-color: rgba(215, 54, 54, 0.3);
$success-color: rgba(2, 218, 110, 0.3);
$half-success-color: rgba(105, 219, 162, 0.3);

$today-border: 3px solid #369cd7;
$today-border-radius: 4px;

@import 'modules/common/components/variables.scss';

.formFrame {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    position: relative;

    .fields {
        flex: 1 auto;
        overflow-y: auto;
        padding: 2px;
    }

    .buttons {
        border-top: $standard-border;
    }
}

:global {
    .collabora-anchors-help {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .collabora-anchors-list {
        min-width: 315px;
    }
}

@mixin planr-uploader-color() {
    border: 2px dashed $planr-input-color;
    box-sizing: border-box;
    border-radius: 4px;
    transition: 0.2s ease-in;

    &:hover {
        border-color: $planr-brand-color;
    }

    &:active {
        border-color: $planr-brand-color-dark;

        .uploader-icon {
            color: $planr-brand-color-dark;
        }
    }
}

.planr-dnduploader {
    z-index: 1;
    min-width: 200px;
    max-width: 476px;
    height: 61px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    @include planr-uploader-color();
    @include planr-font("small");
    position: relative;

    .text-item {
        line-height: 12px;
        cursor: default;
    }

    .uploadlink {
        transition: 0.2s ease-in;
        color: $planr-brand-color;
        cursor: pointer;
        &:active {
            color: $planr-brand-color-dark;
        }
    }

    .uploader-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .planr-icon + span {
        margin-left: $planr-control-space;
    }

    .uploader-icon {
        transition: 0.2s ease-in;
        margin-right: 10px;
        height: 27px;
        color: $planr-brand-color;
        display: flex;
        align-items: center;
    }

    .file-uploader {
        display: flex;
        align-items: center;
    }

    .local-loader {
        display: block;
        position: absolute;
        background: chocolate;
        height: 100%;
        width: 100%;
        border: 1px solid black;
        z-index: 9999;
    }
}

.planr-dnduploader + .planr-dnduploader {
    margin-left: $planr-control-space;
}

$half-grid-size: $pt-grid-size / 2;
$item-left-padding: 9px;
$menu-top-padding: $standard-padding * 1.5;

.rost .main-menu {
    border-top-left-radius: $standard-padding;
    border-top-right-radius: $standard-padding;
    padding-top: $menu-top-padding;
    background-color: $standard-blue;
    flex: 1 1;

    .bp3-menu {
        background: transparent;

        .bp3-menu-item {
            position: relative;
            color: $white;
            padding: 3px $item-left-padding;

            &.bp3-intent-primary {
                background-color: transparent;
                font-weight: bold;
            }

            &.bp3-intent-danger {
                background-color: transparent;
                font-weight: bold;
                color: $standard-orange;
            }

            &:hover {
                background-color: transparent;
                color: rgb(153, 177, 197);
            }
        }

        &:not(.root) {
            border-left: 1px solid $standard-orange;
        }

        .skip-line:before {
            content: "";
            position: absolute;
            top: -2px;
            left: -30px;
            width: 30px;
            height: 30px;
            background-color: $standard-blue;
        }
    }
}

.order-list-wrapper {
    $list-padding: calc(#{$menu-top-padding} + #{$item-left-padding});
    position: absolute;
    top: 126px;
    right: 0;
    height: calc(100% - 126px);

    .main-menu-bottom {
        border-bottom-left-radius: $standard-padding;
        border-bottom-right-radius: $standard-padding;
        background-color: $standard-blue;
        height: $standard-padding;

        margin-bottom: $standard-padding * 0.4;
    }

    .order-list {
        border-radius: $standard-padding;
        padding: $list-padding 0 $standard-padding 0;
        background-color: $standard-blue;
        color: $white;

        .order-list-title {
            padding-left: $list-padding;
        }
    }
}

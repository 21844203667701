@import "modules/common/components/variables.scss";

.workDays {
    display: flex;
    color: $standard-planr-darkblue;
    font-size: 12px;

    span {
        display: flex;
        align-items: center;
        background-color: #edf1f2;
        padding: 5px 6px;
        border-radius: 4px;
        &:first-of-type {
            margin-right: $standard-padding;
        }

        svg {
            width: 16px !important;
            height: 16px !important;
            margin-right: $standard-padding/2 !important;
        }
    }
}

#root .bp3-label .field-asterisk {
    color: $error-red;
    position: absolute;
}

.file-uploader {
    min-height: 22px;
    min-width: 22px;
    line-height: 0;

    .bp3-spinner {
        display: inline-block;
        vertical-align: top;
    }
}

/*.bp3-label {
    color: $gray1;
}*/

.rost {
    /* radio, checkbox */
    .bp3-control.bp3-checkbox,
    .bp3-control.bp3-radio {
        .bp3-control-indicator {
            font-size: 14px;
            position: relative;
            border-radius: $standard-radius;
        }

        input:focus ~ .bp3-control-indicator {
            outline: none;
        }

        input:checked ~ .bp3-control-indicator {
            background-color: white;

            &::before {
                background-image: none;
                background-color: $standard-blue;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                top: 2px;
                left: 2px;
                position: absolute;
            }
        }

        &.bp3-large {
            .bp3-control-indicator {
                font-size: 22px;
            }

            input:checked ~ .bp3-control-indicator::before {
                width: 16px;
                height: 16px;
                top: 3px;
                left: 3px;
            }
        }
    }

    .bp3-popover-wrapper.bp3-fill {
        display: block;
    }

    .bp3-popover-wrapper.bp3-fill .bp3-popover-target {
        width: 100%;
    }
}

.file-link {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 3px;
    color: $standard-planr-darkblue;
    border: 2px dashed $standard-planr-gray-color;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    padding: 12px;
    cursor: default;
    min-width: 200px;
    position: relative;
    max-width: 476px;
    margin-bottom: 5px;

    &.highlight {
        background-color: lighten(#fafad2, 6%);
        border-color: darken($standard-planr-gray-color, 5%);
    }

    a {
        color: $standard-planr-darkblue;
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 60px);
    }

    .bp3-icon {
        cursor: pointer;
    }
}

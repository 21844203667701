.pager {
    .bp3-button {
        border-radius: 0;

        &:first-of-type {
            border-top-left-radius: $block-radius;
            border-bottom-left-radius: $block-radius;
        }

        &:last-of-type {
            border-top-right-radius: $block-radius;
            border-bottom-right-radius: $block-radius;
        }

        &.bp3-intent-primary {
            background-color: $standard-blue;
        }
    }
}


/** buttons */
.compact-action {
    cursor: pointer;
}

.bp3-button .right-text {
    float: right;
}

@import "./planr-scrolls.scss";

$planr-input-background-colors: (
    default: $planr-input-color,
    calendar: $planr-input-color,
);

$planr-input-hover-background-colors: (
    default: $planr-white-color,
    calendar: $planr-white-color,
);

$planr-input-disabled-background-colors: (
    default: $planr-disabled-input-color,
    calendar: $planr-disabled-input-color,
);

$planr-input-active-background-colors: (
    default: $planr-white-color,
    calendar: $planr-white-color,
);

$planr-input-text-colors: (
    default: $planr-font-color,
    calendar: $planr-font-color,
);

$planr-input-hover-text-colors: (
    default: $planr-font-color,
    calendar: $planr-font-color,
);

$planr-input-active-text-colors: (
    default: $planr-font-color,
    calendar: $planr-font-color,
);

$planr-input-padding: (
    medium: 12px 16px,
    small: 8px 10px,
);

$planr-input-height: (
    medium: 38px,
    small: 27px,
);

@mixin planr-input-color($type: "default", $border: solid transparent) {
    background-color: map-get($planr-input-background-colors, $type);
    color: map-get($planr-button-text-colors, $type);
    border: 3px $border;
    transition: 0.2s ease-in;
    outline: none;

    &:disabled,
    &.disabled {
        border-style: solid;
        border-color: map-get($planr-input-disabled-background-colors, $type);
        background-color: map-get($planr-input-disabled-background-colors, $type);
        color: $planr-font-color-light;
    }

    &:read-only,
    &.read-only {
        border-color: map-get($planr-input-background-colors, $type);
        background-color: map-get($planr-input-background-colors, $type);
        color: $planr-readonly-input-text;
    }

    &:focus,
    &.focus {
        border-style: solid;
        border-color: #369cd7;
        background-color: map-get($planr-input-hover-background-colors, $type);
    }

    &:hover,
    &.hover {
        &:not(:disabled),
        &:not(:disabled) {
            border-color: map-get($planr-input-hover-background-colors, $type);
            background-color: map-get($planr-input-hover-background-colors, $type);
            color: map-get($planr-input-hover-text-colors, $type);
        }
    }

    &:active,
    &.active {
        &:not(:disabled),
        &:not(:disabled) {
            border-color: map-get($planr-input-active-background-colors, $type);
            background-color: map-get($planr-input-active-background-colors, $type);
            color: map-get($planr-input-active-text-colors, $type);
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
        }
    }
}

@mixin planr-input-layout($type: "medium") {
    height: map-get($planr-input-height, $type);
    padding: map-get($planr-input-padding, $type);
    border-radius: $planr-border-radius;

    &.is-round {
        border-radius: 120px;
    }

    &.is-readonly {
        border-color: map-get($planr-input-background-colors, $type);
        background-color: map-get($planr-input-background-colors, $type);
        color: $planr-readonly-input-text;
    }
}

.planr-input {
    width: 244px;

    .bp3-input {
        position: relative;
        padding: 15px 20px;
        height: 52px;
        background-color: $planr-input-color;
        border: 3px solid transparent;
        border-radius: 120px;
        transition: 0.2s ease-in;
        outline: none;
        @include planr-font("standard");
        &:disabled,
        &.disabled {
            cursor: default;
        }

        &:hover {
            border-color: $planr-white-color;
            color: $planr-font-color;
            background-color: $planr-white-color;
        }

        &:active {
            border-color: $planr-white-color;
            background-color: $planr-white-color;
            color: $planr-font-color;
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
        }

        &:focus {
            border-style: solid;
            border-color: #369cd7;
            background-color: $planr-white-color;
        }

        &.default {
            @include planr-input-color("default");
        }

        &.calendar {
            @include planr-input-color("calendar");
        }

        &.medium {
            @include planr-input-layout("medium");
        }
        &.small {
            @include planr-input-layout("small");
            @include planr-font("tiny");
        }

        .planr-icon + span {
            margin-left: $planr-control-space;
        }
    }
}

.planr-input-label {
    @include planr-font("standard");
    display: block;
    margin-top: 20px;
    margin-bottom: 6px;
    font-size: $planr-font-size-small;
}

.planr-input-label-required {
    @include planr-font("standard");
    font-size: $planr-font-size-small;
    color: #e31818;
    margin-left: 2px;
}

.icon-input {
    position: absolute;
    display: block;
    margin-left: 292.63px;
    //margin-top: 10px;
    color: #e31818;
}

.input-crossbtn {
    position: absolute;
    top: -7px;
    right: -12px;
}

.input-searchicon {
    position: absolute;
    top: 10px;
    right: 7px;
    color: rgba(0, 39, 61, 0.4);
}
.planr-round-input {
    .bp3-input {
        position: relative;
        padding: 12px 16px;
        height: 38px;
        background-color: $planr-input-color;
        border: 3px solid transparent;
        border-radius: 120px;
        transition: 0.2s ease-in;
        outline: none;
        color: $planr-font-color;
        @include planr-font("standard");
        &:disabled,
        &.disabled {
            cursor: default;
        }

        &:hover {
            border-color: $planr-white-color;
            color: $planr-font-color;
            background-color: $planr-white-color;
        }

        &:active {
            border-color: $planr-white-color;
            background-color: $planr-white-color;
            color: $planr-font-color;
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
        }

        &:focus {
            border-style: solid;
            border-color: #369cd7;
            background-color: $planr-white-color;
        }

        &.default {
            @include planr-input-color("default");
        }

        &.calendar {
            @include planr-input-color("calendar");
        }

        &.medium {
            @include planr-input-layout("medium");
        }
        &.small {
            @include planr-input-layout("small");
            @include planr-font("tiny");
        }

        .planr-icon + span {
            margin-left: $planr-control-space;
        }
    }
}
.planr-default-input {
    .bp3-input {
        position: relative;
        padding: 12px 16px;
        height: 38px;
        background-color: $planr-input-color;
        border: 3px solid transparent;
        border-radius: 4px;
        transition: 0.2s ease-in;
        outline: none;
        color: $planr-font-color;
        @include planr-font("standard");
        &:disabled,
        &.disabled {
            cursor: default;
        }

        &:hover {
            border-color: $planr-white-color;
            color: $planr-font-color;
            background-color: $planr-white-color;
        }

        &:active {
            border-color: $planr-white-color;
            background-color: $planr-white-color;
            color: $planr-font-color;
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
        }

        &:focus {
            border-style: solid;
            border-color: #369cd7;
            background-color: $planr-white-color;
        }

        &[readonly] {
            box-shadow: none;
            cursor: not-allowed;
        }

        &.default {
            @include planr-input-color("default");
        }

        &.calendar {
            @include planr-input-color("calendar");
        }

        &.medium {
            @include planr-input-layout("medium");
        }
        &.small {
            @include planr-input-layout("small");
            @include planr-font("tiny");
        }

        .planr-icon + span {
            margin-left: $planr-control-space;
        }
    }
}

.planr-tiny-input {
    .bp3-input {
        position: relative;
        padding: 8px 10px;
        height: 27px;
        background-color: $planr-input-color;
        border: 3px solid transparent;
        border-radius: 4px;
        transition: 0.2s ease-in;
        outline: none;
        color: $planr-font-color;

        @include planr-font("tiny");
        &:disabled,
        &.disabled {
            cursor: default;
        }

        &:hover {
            border-color: $planr-white-color;
            color: $planr-font-color;
            background-color: $planr-white-color;
        }

        &:active {
            border-color: $planr-white-color;
            background-color: $planr-white-color;
            color: $planr-font-color;
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
        }

        &:focus {
            border-style: solid;
            border-color: #369cd7;
            background-color: $planr-white-color;
        }

        &.default {
            @include planr-input-color("default");
        }

        &.calendar {
            @include planr-input-color("calendar");
        }

        &.medium {
            @include planr-input-layout("medium");
        }
        &.small {
            @include planr-input-layout("small");
            @include planr-font("tiny");
        }

        .planr-icon + span {
            margin-left: $planr-control-space;
        }
    }
}

.planr-form-input {
    @include planr-font("standard");
}

.planr-round-select .bp3-popover-target .bp3-button.selected-option {
    position: relative;
    padding: 12px 16px;
    height: 38px;
    background-color: $planr-input-color;
    border: 3px solid transparent;
    border-radius: 120px;
    transition: 0.2s ease-in;
    outline: none;
    color: $planr-font-color;
    @include planr-font("standard");
    &:disabled,
    &.disabled {
        cursor: default;
    }

    &:hover {
        border-color: $planr-white-color;
        color: $planr-font-color;
        background-color: $planr-white-color;
    }

    &:active {
        border-color: $planr-white-color;
        background-color: $planr-white-color;
        color: $planr-font-color;
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
    }

    &:focus {
        border-style: solid;
        border-color: #369cd7;
        background-color: $planr-white-color;
    }

    &.default {
        @include planr-input-color("default");
    }

    &.calendar {
        @include planr-input-color("calendar");
    }

    &.medium {
        @include planr-input-layout("medium");
    }
    &.small {
        @include planr-input-layout("small");
        @include planr-font("tiny");
    }

    .planr-icon + span {
        margin-left: $planr-control-space;
    }
}
.select .bp3-popover-target .bp3-button.selected-option {
    position: relative;
    padding: 12px 16px;
    height: 38px;
    background-color: $planr-input-color;
    border: 3px solid transparent;
    border-radius: 4px;
    transition: 0.2s ease-in;
    outline: none;
    color: $planr-font-color;
    @include planr-font("standard");
    &:disabled,
    &.disabled {
        cursor: default;
    }

    &:hover {
        border-color: $planr-white-color;
        color: $planr-font-color;
        background-color: $planr-white-color;
    }

    &:active {
        border-color: $planr-white-color;
        background-color: $planr-white-color;
        color: $planr-font-color;
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
    }

    &:focus {
        border-style: solid;
        border-color: #369cd7;
        background-color: $planr-white-color;
    }

    &.default {
        @include planr-input-color("default");
    }

    &.calendar {
        @include planr-input-color("calendar");
    }

    &.medium {
        @include planr-input-layout("medium");
    }
    &.small {
        @include planr-input-layout("small");
        @include planr-font("tiny");
    }

    .planr-icon + span {
        margin-left: $planr-control-space;
    }
}

.bp3-form-content {
    label.bp3-switch {
        display: inline-block;
    }

    //     /* no margins for button inside select */
    .bp3-input-group .planr-default-input .bp3-button {
        position: relative;
        padding: 12px 16px;
        height: 38px;
        background-color: $planr-input-color;
        border: 3px solid transparent;
        border-radius: 4px;
        transition: 0.2s ease-in;
        outline: none;
        color: $planr-font-color;
        @include planr-font("standard");
        &:disabled,
        &.disabled {
            cursor: default;
        }

        &:hover {
            border-color: $planr-white-color;
            color: $planr-font-color;
            background-color: $planr-white-color;
        }

        &:active {
            border-color: $planr-white-color;
            background-color: $planr-white-color;
            color: $planr-font-color;
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
        }

        &:focus {
            border-style: solid;
            border-color: #369cd7;
            background-color: $planr-white-color;
        }

        &.default {
            @include planr-input-color("default");
        }

        &.calendar {
            @include planr-input-color("calendar");
        }

        &.medium {
            @include planr-input-layout("medium");
        }
        &.small {
            @include planr-input-layout("small");
            @include planr-font("tiny");
        }

        .planr-icon + span {
            margin-left: $planr-control-space;
        }
    }

    .bp3-input-group .planr-round-input .bp3-button {
        position: relative;
        padding: 12px 16px;
        height: 38px;
        background-color: $planr-input-color;
        border: 3px solid transparent;
        border-radius: 120px;
        transition: 0.2s ease-in;
        outline: none;
        color: $planr-font-color;
        @include planr-font("standard");
        &:disabled,
        &.disabled {
            cursor: default;
        }

        &:hover {
            border-color: $planr-white-color;
            color: $planr-font-color;
            background-color: $planr-white-color;
        }

        &:active {
            border-color: $planr-white-color;
            background-color: $planr-white-color;
            color: $planr-font-color;
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
        }

        &:focus {
            border-style: solid;
            border-color: #369cd7;
            background-color: $planr-white-color;
        }

        &.default {
            @include planr-input-color("default");
        }

        &.calendar {
            @include planr-input-color("calendar");
        }

        &.medium {
            @include planr-input-layout("medium");
        }
        &.small {
            @include planr-input-layout("small");
            @include planr-font("tiny");
        }

        .planr-icon + span {
            margin-left: $planr-control-space;
        }
    }

    //     .bp3-input-group .planr-default-select .bp3-button {
    //         position: relative;
    //         padding: 8px 10px;
    //         height: 38px;
    //         background-color: $standard-planr-gray-color
    //         border: 3px solid transparent;
    //         border-radius: 4px;
    //         transition: 0.2s ease-in;
    //         outline: none;
    //         color: $standard-planr-darkblue;
    //     }
}

textarea.bp3-input {
    @include planr-scoll(5px);
}

.planr-default-input.date-picker {
    width: 100%;
    display: inline-block;

    .bp3-popover-target {
        width: 100%;
        display: inline-block;
    }

    .bp3-small {
        .input-crossbtn {
            height: 42px;
        }
        .input-searchicon {
            top: 5px;
        }
    }
}

@import "modules/root/styles/theme/blueprint/variables.scss";
@import "./common";

.icon {
    width: 45px;
    min-width: 45px;
    height: 35px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    cursor: pointer;
    opacity: 0.8;
    margin: $icon/2 10px 15px 10px;
    float: left;
    clear: none;
    box-shadow: $pt-elevation-shadow-1;

    &:hover {
        opacity: 1;
    }

    :global {
        .bp3-spinner {
            margin-top: 10px;
        }
    }

    &.uploader {
        .plus {
            color: #aca785;
            opacity: 0.7;
            position: absolute;
            top: calc(50% - 7px);
            left: calc(50% - 7px);
        }
    }
}

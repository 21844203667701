@import "modules/common/components/variables.scss";

.card {
    padding-top: $standard-padding / 2;
    position: relative;

    .block {
        transition: 0.2s ease-in;
        margin-bottom: 8px;
        border-radius: 4px;
        border: 2px solid $standard-planr-gray-color;
        padding: 10px;
        background-color: #fff;
        cursor: pointer;

        &:hover,
        &.selected {
            border-color: #ea561e;
        }
    }

    .blockHeader {
        display: flex;
        flex-direction: column;
    }

    :global {
        .file-uploader {
            margin-right: $standard-padding;
        }
    }
}

.fileList {
    margin-top: $standard-padding / 2;
    margin-bottom: $standard-padding / 2;
    overflow: auto;
}

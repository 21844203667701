@import "./_common.scss";

.row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $standard-planr-gray-color;
    color: $standard-planr-darkblue;
    background-color: #fff;
    padding-left: 10px;

    & > div {
        height: $cell;
    }
}

.editable {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $standard-planr-gray-color;
    color: $standard-planr-darkblue;
    background-color: #fff;
    padding-left: 10px;

    transform: scale(1.01);

    z-index: 1000;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
}

.workedOut {
    width: 86.5px;
}

.dayOff,
.overtime {
    width: 180px;
}

.fromHome {
    width: 160px;
}

.missing {
    width: 90px;
}

.overtimeScale {
    width: 90px;

    input {
        max-width: 30px;
    }

    .edit {
        display: inline-block;
        margin-top: -4px;
        margin-left: 5px;
        cursor: pointer;
        max-height: 12px;
    }
}

.cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.expander {
    cursor: pointer;
    margin-left: 5px;
}

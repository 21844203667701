@import "@blueprintjs/core/src/common/_variables.scss";
@import "modules/common/components/variables.scss";

.picker {
    padding: 0;
    height: 38px;
    box-shadow: $pt-input-box-shadow;
    border-radius: $standard-radius;
    border: transparent;
    position: relative;

    &.small {
        height: $pt-input-height-small;
    }

    &.active {
        cursor: pointer;
    }
}

@import "modules/root/styles/planr/planr-scrolls.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

.wrapper {
    width: 100%;
    max-height: 750px;
    height: 750px;

    font-size: 12px;
    // .mainRow:nth-child(odd) {
    //     background-color: #f1f1f1;
    // }
    .scroller {
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }
    color: $planr-font-color;
    .mainRow {
        padding: 4px 10px;
        font-size: 13px;
        .userRow {
            font-size: 14px;
            display: flex;
            margin: 10px 0px;
            padding: 3px 7px;
            width: 100%;
            background-color: $planr-font-color-light;
            color: #fff;
            .date {
                width: 160px;
            }
            .user {
                width: 300px;
            }
        }

        .logRows {
            margin-left: 100px;
            .logRowWrapper {
                padding: 3px;
                background-color: #fff;
            }

            .logRowWrapper:nth-child(even) {
                padding: 3px;
                background-color: #f1f1f1;
            }
            .logRow {
                display: flex;
                align-items: center;

                padding: 4px;

                .values {
                    display: flex;
                    align-items: center;
                }

                .status {
                    width: 80px;
                }

                .fieldName {
                    width: 160px;
                    color: #a681d8;
                }

                .value {
                    color: #1dd278;
                    max-width: 200px;
                }
                .prevValue {
                    color: #d73636;
                    max-width: 200px;
                }
                .fieldNameMain {
                    width: 160px;
                    color: #7547b1;
                    span {
                        border-bottom: 2px solid #a681d8;
                    }
                }

                .defvalue {
                    width: 400px;
                }

                .tabName {
                    display: flex;
                    flex-direction: column;
                    // align-items: center;

                    color: $planr-brand-color;
                    width: 145px;
                    // background-color: $planr-brand-color-light;
                    //  border-radius: 4px;
                    //border-bottom: 2px solid $planr-brand-color;
                    // padding: 5px;
                    .tabLabel {
                        font-size: 11px;
                        color: $planr-brand-color;
                    }
                }
            }
        }
    }
}

.removed {
    background-color: #ebdddf;
    color: #d73636;
    display: flex;
    border-radius: 4px;
    font-weight: 700;
    width: 70px;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    width: 80px;
}

.created {
    color: #1dd278;
    background-color: #d8ede6;
    display: flex;
    border-radius: 4px;
    width: 70px;
    align-items: center;
    justify-content: center;
    cursor: help;
    font-weight: 700;
    font-size: 11px;
    width: 80px;
}

.updated {
    color: #28b8ec;
    background-color: rgba(54, 172, 215, 0.15);
    display: flex;
    border-radius: 4px;
    width: 70px;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    width: 80px;
    font-weight: 700;
}

.mainVal {
    white-space: normal;
    text-overflow: ellipsis;
    display: flex;
    flex: 1 1;
    margin-right: 10px;
    margin-left: 10px;
}

@import "modules/common/components/variables.scss";

.content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow: hidden;
}

.contents {
    flex: 1 1;
    overflow-y: auto;
}
.clearInfo {
    color: $standard-planr-darkblue;
}
.plus {
    margin-left: $standard-padding;
}

@import "modules/root/styles/planr/variables.scss";

.wrapper {
    width: 1px;
    z-index: 1000;

    :global {
        .order-list {
            font-family: "GothamPro", Arial, sans-serif;
            color: $standard-planr-darkblue;
            font-size: 12px;
            background-color: #fff;
            position: absolute;
            width: 570px;
            z-index: 1000;
            left: 14px;
            top: -40px;
            bottom: 0;
            box-sizing: border-box;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
        }
    }
}

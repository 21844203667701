@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

.event {
    padding: 5px;
    margin: 15px 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    background-color: $light-planr-gray;

    &:hover {
        border: 1px solid darken($standard-planr-gray-color, 10%);
    }

    .deleteButton {
        @include planr-font("small");
        height: auto;
        line-height: 14px;
        padding: 5px 7px;
    }

    :global {
        .bp3-form-group {
            margin-bottom: 0;
        }

        .bp3-form-group + .bp3-form-group {
            margin-top: 1px;
            margin-left: 0 !important;
        }

        .bp3-input {
            font-size: map-get($planr-font-sizes, "small") !important;
            padding: 5px 7px !important;
            margin-top: 1px;
            height: 30px;
        }

        .bp3-checkbox {
            font-size: map-get($planr-font-sizes, "small") !important;
            margin: 0 !important;
        }

        .bp3-label {
            margin-bottom: 0 !important;
        }

        .selected-option {
            height: auto !important;
            line-height: map-get($planr-font-sizes, "standard") !important;
            padding: 4px 6px !important;
            font-size: map-get($planr-font-sizes, "small") !important;

            .bp3-button-text {
                white-space: normal !important;
            }
        }
    }
}

.eventsFormItem {
    :global {
        .selected-option,
        .planr-button {
            font-size: map-get($planr-font-sizes, "small") !important;
        }

        .selected-option {
            padding: 4px 6px !important;
        }
    }
}

.event + .event {
    margin-top: $standard-padding;
}

.addButton {
    margin-top: 2px;
    width: 100%;
}

:global {
    .business-event__typeselect-dropdown {
        width: auto !important;

        .bp3-menu {
            overflow-y: visible;
            max-height: 100px;
            overflow-x: hidden;
        }

        .bp3-menu-item {
            width: 180px;
            font-size: 12px;
        }
    }

    .business-event__break-dropdown {
        .bp3-text-overflow-ellipsis {
            white-space: normal;
            font-size: 10px;
        }
    }
}

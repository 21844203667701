@import "modules/root/styles/planr/variables.scss";

.odd {
    background-color: $planr-disabled-color;
}

.empty {
    color: rgba($color: $standard-planr-darkblue, $alpha: 0.4);
}

/* use legacy table-based layout to correct image convertation */
.statuses {
    padding: 2px 5px;

    min-height: 110px;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;

    table {
        width: 100%;
    }

    td {
        height: 26px;
        line-height: 26px;
        margin: 0;
        padding: 0;
        border-collapse: collapse;
        border: 0;
        border-bottom: 1px solid transparent;

        &.count {
            font-size: 12px;
            text-align: right;
        }
    }
}

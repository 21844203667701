form[autocomplete="off"] input[type="text"] {
    background-position: 150% 50% !important;
}

.bp3-select-popover input[type="text"] {
    background-position: 150% 50% !important;
}

.bp3-input-group {
    .bp3-popover-wrapper,
    .bp3-popover-target {
        display: block;
    }
}

.bp3-form-group {
    &.bp3-inline.bp3-small {
        label.bp3-label {
            line-height: $pt-input-height-small;
        }
    }
}

.bp3-select-popover .bp3-menu-item.bp3-intent-primary.bp3-active {
    color: $standard-planr-darkblue;
}

.bp3-select-popover .bp3-popover-content {
    padding: 0;
}
.bp3-menu {
    color: $standard-planr-darkblue;
    font-family: GothamPro, Arial, sans-serif;
}
.bp3-popover {
    border: none;
    border-radius: 4px;

    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
}

/* no full-width checkbox label (prevet onChange event on emty space click) */
.bp3-form-content {
    label.bp3-switch {
        display: inline-block;
    }

    //     /* no margins for button inside select */
    .bp3-input-group .full-width-select .bp3-button {
        margin: 0;
    }
}

/* large text in input */
.bp3-input-group.bp3-small .bp3-input {
    font-size: inherit;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.clearable-select-content {
    display: flex;
    align-items: center;

    .bp3-icon {
        cursor: pointer;
        margin-left: 2px;
        color: $gray3;
    }
}

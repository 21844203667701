@import "modules/common/components/variables.scss";

.details {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-left: $standard-padding / 2;
    overflow: hidden;
    position: relative;

    .detailsTools {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        // height: 30px;
        .detailsBtns {
            display: flex;
        }
        // svg {
        //     margin-left: $standard-padding;
        // }
    }

    .detailsFiles {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        background-color: $standard-planr-gray-color;
        padding: $standard-padding;
    }

    .viewer {
        flex: 1 auto;
        display: flex;
        flex-direction: column;

        margin-top: $standard-padding;
    }

    :global {
        .previewer {
            width: 100%;
            overflow-y: hidden;
        }
    }
}

@media (max-width: 2000px) {
    .details {
        max-width: 630px;
    }
}

@import 'modules/common/components/variables.scss';

.editor {
    min-height: 300px;
    border: $control-border;
    border-radius: $standard-radius;
}

.dialog {
    :global {
        .ql-editor {
            min-height: 300px;
        }

        /* locale */

        .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
        .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
            content: 'Заголовок 1';
        }

        .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
        .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
            content: 'Заголовок 2';
        }

        .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
        .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
            content: 'Заголовок 3';
        }

        .ql-snow .ql-picker.ql-header .ql-picker-label::before,
        .ql-snow .ql-picker.ql-header .ql-picker-item::before {
            content: 'Текст';
        }

        .ql-snow .ql-tooltip[data-mode=link]::before {
            content: "Ссылка:";
        }

        .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
            content: "Сохранить";
        }
    }
}

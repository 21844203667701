@import "modules/common/components/variables.scss";

.content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow: hidden;
}

.contents {
    flex: 1 1;
    overflow-y: auto;
}
.clearInfo {
    color: $standard-planr-darkblue;
}
.plus {
    margin-left: $standard-padding;
}

.listDialog {
    width: 1000px;
    height: 730px;
}
.dialogBtn {
    margin-bottom: 10px;
    margin-top: -20px;
}
.dialogTable {
    height: 500px;
    overflow-y: scroll;
    width: 100%;
    color: $standard-planr-darkblue;
    .dialogRow:nth-child(odd) {
        background-color: #f7f9fa; /* фон нечетных строк */
    }
    .dialogRow {
        display: flex;
        padding: 10px 0px;
        align-items: center;

        .dialogCheck {
            width: 30px;
            padding-left: 5px;
        }

        .dialogPart {
            width: 60px;
        }

        .dialogName {
            width: 120px;
        }
        .dialogName {
            width: 200px;
        }
        .dialogDescription {
            flex: 1 1;
        }
    }
}

.rost {
    $header-color: #f5f7f7;

    .no-last-column-break {
        .ag-header-row .ag-header-cell:last-of-type:after {
            display: none;
        }
    }

    .ag-theme-balham {
        /** figma styles */

        &.designed {
            $my-font-size: $font-size-small;
            font-family: $font;
            font-size: $my-font-size;
            position: relative;

            &.dark-header {
                .ag-header-viewport {
                    background-color: $standard-blue;
                    border-top-left-radius: $table-header-radius;
                }

                &.rounded {
                    .ag-header-viewport {
                        border-top-right-radius: $table-header-radius;
                    }
                }

                .ag-header-container {
                    .ag-header-cell-text {
                        color: $white;
                        font-size: 14px;
                    }
                }

                .ag-header-icon i {
                    color: $white !important;
                }

                .ag-column-hover .ag-header-cell-resize {
                    &:hover {
                        background-color: rgba($color: #fff, $alpha: 0.5);
                    }
                }

                .ag-react-container {
                    width: 100%;
                    display: flex;
                    position: relative;
                }
            }

            /* header border */
            .ag-header-cell::after,
            .ag-header-group-cell::after {
                display: none;
            }

            .ag-cell {
                line-height: $table-row-height;
            }

            .ag-ltr {
                .ag-cell {
                    border-right-width: 0;
                }
            }

            .ag-rtl {
                .ag-cell {
                    border-left-width: 0;
                }
            }

            .ag-root-wrapper {
                border: none;
            }

            .ag-header {
                border-bottom-color: #d9dcde;
                background-color: white;
            }

            .ag-header-cell-text {
                font-size: 14px;
                color: $standard-planr-darkblue;
            }

            .bp3-input {
                height: $table-row-height;

                box-shadow: none;
                font-size: $my-font-size + 1px;
                border-radius: 0;
                background-color: transparent;
            }

            .bp3-input-action {
                .bp3-button {
                    background-color: transparent;
                    background-image: none;
                    padding: 0;
                    min-width: $table-row-height;
                    min-height: $table-row-height;
                    margin-top: 0;
                    margin-bottom: 0;

                    svg {
                        width: $figma-table-icon-size;
                        height: $figma-table-icon-size;
                    }
                }
            }

            .ag-checkbox-input-wrapper {
                &.ag-checked {
                    &::after {
                        color: $standard-blue;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        border-radius: 3px;
                        top: 3px;
                        left: 3px;
                        bottom: 3px;
                        right: 3px;
                        background-image: none;
                        background-color: $standard-blue;
                    }
                }
            }
        }
        /* figma end */

        .ag-header-cell-menu-button {
            display: none;
        }

        /* header border */
        .ag-header-cell::after,
        .ag-header-group-cell::after {
            height: 100%;
            display: none;
            top: 0;
        }
        .ag-pivot-off {
            border: none;
        }
        .ag-header-viewport,
        .ag-body-viewport,
        .ag-center-cols-viewport {
            background-color: #f5f7f8;
        }
        .ag-row-selected {
            background-color: #e1e6e9;
        }
        /* bordered table */
        &.bordered {
            .ag-row .ag-cell {
                border-right-color: #d9dcde;
            }
        }

        .ag-floating-bottom {
            .ag-cell {
                line-height: initial;
            }

            .ag-row {
                background-color: $header-color;
            }
        }

        .ag-cell.row-number {
            padding: 0 5px 0 0;
            color: #5c7080;

            .ag-cell-value {
                min-width: 22px;
            }
        }

        .ag-row:not(.ag-row-selected) .ag-cell.row-number {
            background-color: $header-color;
        }

        .ag-menu {
            border-radius: 3px;
        }

        .ag-ltr {
            border: none;
            .ag-selection-checkbox {
                margin-right: 2px;
            }
        }

        .bp3-control {
            margin: 0 !important;
            padding: 0 !important;
        }

        .bp3-control-indicator {
            margin: 0 !important;
            padding: 0 !important;
        }

        .bp3-checkbox {
            .bp3-control-indicator {
                margin-top: -2px !important;
            }
        }
    }

    .ag-header-cell-text {
        color: $standard-planr-darkblue;
        font-size: 14px;
        font-weight: normal;
        font-family: "GothamPro", Arial, sans-serif;
        border-bottom: none;
    }

    .righted .ag-cell-wrapper {
        justify-content: flex-end;
    }

    .centered {
        .ag-cell-wrapper,
        .ag-react-container,
        .ag-header-cell-label {
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }
    .stretched {
        .ag-cell-value {
            width: 100%;
        }
    }

    .ag-header-cell.flat,
    .ag-cell.flat {
        padding-left: 0;
        padding-right: 0;
    }

    table.bp3-html-table th {
        text-align: center;
    }

    .ag-menu-option-text {
        font-size: $font-size;
        line-height: 20px;
        display: block;
    }
}

.root {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;

    .header {
        cursor: move;
    }

    :global {
        .bp3-dialog-body {
            display: flex;
        }
    }
}

:global {
    @for $i from 1 through 50 {
        .draggable-dialog-#{$i} {
            z-index: 20 + $i;
        }
    }
}

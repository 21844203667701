@import "modules/common/components/variables.scss";

.spendings {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: 98%;

    .inner {
        flex-wrap: wrap;
        display: flex;
        flex: 1 1;
        flex-direction: row;
        align-content: baseline;
        padding-top: 25px;
        position: relative;
        .spendingsTotal {
            position: absolute;
            top: -22px;
            display: flex;
            width: 100%;
        }
    }

    :global {
        table.spendings tbody tr:first-child td {
            box-shadow: none;
        }

        .money {
            font-size: 100%;
        }

        .timesheet-spendings-total,
        .plan-total,
        .premium-bank,
        .final-bank {
            position: absolute;

            font-size: 10px;
            color: $standard-planr-darkblue;

            font-weight: 700;
            border-radius: 4px;
            background-color: #e1e6e9;
            padding: 5px 7px;

            .spendings-money {
                font-size: 10px;
                color: $standard-planr-darkblue;
                font-weight: 700;
                font-family: "GothamPro", Arial, sans-serif;
            }
        }

        .red-total {
            background-color: #ebdddf;
            color: #d73636;

            .spendings-money {
                color: #d73636;
            }
        }

        .green-total {
            color: #1dd278;
            background-color: #d8ede6;

            .spendings-money {
                color: #1dd278;
            }
        }

        .actual-total,
        .own-spendings .spendings-total,
        .final-bank {
            left: 620px;
        }

        .timesheet-spendings-total {
            right: 250px;
        }
        .order-total,
        .plan-total,
        .premium-bank {
            left: 200px;
        }

        .outsourced-spendings,
        .other-spendings,
        .own-spendings,
        .order-premium {
            width: 100%;
            position: relative;

            .bp3-collapse-body {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

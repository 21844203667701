@import "modules/common/components/variables.scss";

.tab {
    flex: 1 auto;
    display: flex;
    flex-direction: row;
    overflow: auto;
    margin-right: 16px;
    height: 100%;

    :global {
        .side-panel,
        .mails {
            margin-top: 20px;
        }

        .mails {
            min-width: 700px;
        }

        .documents {
            overflow-x: auto;
        }

        .single-file {
            width: 40px;
            min-width: 40px;
            height: 30px;
        }
    }
}

@import "./common";

.week-days + .timesheet-user-row {
    .timesheet-cell {
        border-top: $border;
    }
}

.timesheet-user-row:last-of-type {
    .timesheet-cell.is-today {
        border-bottom: $today-border;
        border-bottom-left-radius: $today-border-radius;
        border-bottom-right-radius: $today-border-radius;

        &.selected {
            border-radius: 0;
        }
    }
}

.timesheet-user-name,
.month-user-name {
    width: 309px;
    min-width: 309px;
    padding-right: $standard-padding;

    display: flex;

    .employer-name {
        flex: 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }
}

.employer-position {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 39, 61, 0.4);
}

.employer-name-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.timesheet-cell {
    position: relative;
    width: 40px;
    min-width: 40px;
}

@import "modules/common/components/variables.scss";

.filters {
    margin-top: 24px;
    $filter-width: 200px;
    display: flex;
    flex-direction: column;
    padding-right: $standard-padding;
    margin-bottom: $standard-padding;
    margin-right: 16px;

    .filtersRow {
        display: flex;
        flex-direction: row;
        //margin-bottom: $standard-padding/2;
        align-items: center;
        color: $standard-planr-darkblue;
        background-color: #f5f7f8;
        .rowLabels:first-child {
            margin: 0px;
            padding: 0px;
            display: flex;
            font-size: 12px;

            label + label {
                margin-left: 312px;
            }
        }

        .rowLabels:nth-child(2) {
            display: flex;

            label + label {
                margin-left: 10px;
            }
        }
    }

    .filter {
        max-width: $filter-width;
        margin-right: $standard-padding;
    }

    .currency {
        color: $standard-blue;
        font-weight: bold;
        display: inline-block;
        margin-right: $standard-padding/2;
    }

    :global {
        .bp3-popover-target .bp3-button.selected-option {
            justify-content: flex-start;

            .bp3-button-text {
                flex: 0 1 auto;
            }
        }

        .date-range-input {
            margin-right: $standard-padding * 2;

            .bp3-input-group:first-child .bp3-input {
                max-width: $filter-width * 0.55;
                padding-right: 0 !important;
                text-align: center;
                border-bottom-color: $standard-gray !important;
                border-radius: 0 !important;
            }

            .bp3-input-group:last-child .bp3-input {
                max-width: $filter-width * 0.5+45px;
                text-align: center;
                border-bottom-color: $standard-gray !important;
                border-radius: 0 !important;
            }
        }

        .sum-range-input {
            & > :first-child,
            & > :first-child .bp3-input {
                text-align: right;
                max-width: $filter-width * 0.5;
                border-bottom-color: $standard-gray !important;
                border-radius: 0 !important;
            }

            & > :last-child,
            & > :last-child .bp3-input {
                text-align: right;
                max-width: $filter-width * 0.5+45px;
                border-bottom-color: $standard-gray !important;
                border-radius: 0 !important;
            }

            & > :last-child::before {
                content: "—";
                position: absolute;
                z-index: 15;
                left: 5px;
                top: 0;
                bottom: 0;
                line-height: $control-height;
            }

            &.focused {
                .bp3-input {
                    box-shadow: none;
                    border-color: $black;
                }
            }

            .bp3-input {
                text-align: center;
            }
        }
    }
}

.marker {
    display: inline-block;
    width: $control-height/2;
    height: $control-height/2;
    border-radius: $control-height/4;
    background-color: darken(#eefff3, 20%);
    align-self: center;
    margin-top: -3px;

    &.production {
        background-color: darken(#fff0e3, 20%);
    }
}

@import "./_common.scss";

.header {
    display: flex;
    width: 1573px;

    .cell {
        min-height: $cell;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $standard-planr-darkblue;
        font-size: 14px;

        &:first-child {
            margin-left: 330px;
        }
    }
}

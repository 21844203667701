@import "./_common.scss";
.row {
    display: flex;
    flex-direction: row;
    color: $standard-planr-darkblue;

    :global {
        .unit-user-name {
            background-color: #fff;
            padding-top: 5px;
            padding-left: 20px;
            padding-right: 10px;
            height: 46px;
            border-bottom: $border;
            width: 320px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
        }
    }
}

@import 'modules/common/components/variables.scss';

@mixin icons {
    .inactive {
        path {
            fill: $standard-gray;
        }
    }

    .active {
        path {
            fill: $standard-blue;
            cursor: pointer;
        }
    }

    .inactive,
    .active {
        margin-right: $standard-padding;
    }
}

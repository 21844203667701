.block-header {
    border-radius: 6px;
    height: $blockHeaderHeight;
    min-height: $blockHeaderHeight;
    background-color: $standard-blue;
    font-size: $pt-font-size-small;
    font-weight: normal;
    padding: 0 $standard-padding * 1.5 0 $standard-padding * 1.5;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    color: $white;
    border: 1px solid $standard-blue;

    a {
        color: $white;
    }

    &.collapser {
        cursor: pointer;
        padding-left: $standard-padding * 2;
        margin-bottom: 0;

        .bp3-icon-caret-down,
        .bp3-icon-caret-up,
        .bp3-icon-chevron-down,
        .bp3-icon-chevron-up {
            position: absolute;
            left: 10px;
        }
    }

    &.light-mode {
        background-color: #EAEEF1;
        color: $black;
        border-color: $standard-gray;
    }

    &.flat-mode {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

@import "modules/common/components/variables.scss";

.table {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    flex-direction: column;
    padding-right: $standard-padding;
    margin-top: 20px;

    :global {
        .ag-row {
            background-color: #fff;
            &:hover {
                background-color: $standard-planr-gray-color;
            }
            .ag-cell-value,
            .ag-group-value {
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
            }

            a {
                color: $standard-planr-darkblue;
                border-bottom: 1px dotted $standard-planr-darkblue;
            }
            .ag-cell {
                padding-right: 0 !important;
            }
        }
    }
}

/* date picker icon */
.date-picker .bp3-icon-calendar {
    color: $standard-blue;
    svg {
        width: 12px;
        height: 12px;
    }
}

.DayPicker-wrapper {
    .bp3-icon {
    }
    .bp3-icon > svg {
        path {
            fill: rgba(0, 39, 61, 0.4) !important;
        }
    }
}

.DayPicker-Months {
    .DayPicker-Weekday abbr {
        font-size: $font-size-small;
        color: $standard-blue;
        font-family: Gotham Pro, Arial, Helvetica, sans-serif;
    }
    .DayPicker-Weekday:nth-child(n + 6) {
        abbr {
            color: red;
        }
    }
    .bp3-datepicker-year-select,
    .bp3-datepicker-month-select {
        select {
            color: $standard-planr-darkblue;
            font-family: Gotham Pro, Arial, Helvetica, sans-serif;
        }
    }
}

.DayPicker-Body {
    .DayPicker-Week {
        .DayPicker-Day--outside {
            background: #f7f9fa;

            .bp3-datepicker-day-wrapper {
                color: rgba(0, 39, 61, 0.3);
            }
            &:hover {
                background: #d8e1e8;
            }
        }
        .DayPicker-Day {
            border-radius: 0px;
            border: 1px solid $standard-planr-gray-color;
            font-size: $font-size-small;
            &.DayPicker-Day--isToday {
                background-color: #d8ede6;
            }
            &.DayPicker-Day--isToday .bp3-datepicker-day-wrapper {
                border: none;
            }
            &.DayPicker-Day--selected {
                background-color: #ea561e;
                color: #fff;
                border-radius: 0px;
                .bp3-datepicker-day-wrapper {
                    color: #fff;
                    font-family: Gotham Pro, Arial, Helvetica, sans-serif;
                    margin: 0px;
                }
            }
        }
    }
}
.bp3-html-select select::before,
.bp3-select select::before,
.bp3-html-select select > *,
.bp3-select select > * {
    margin-right: 5px;
    background: #fff;
    color: $standard-blue;
}
.bp3-daterangepicker-shortcuts {
    margin-left: -5px;

    li a {
        padding-left: $standard-padding;
    }

    .bp3-active.bp3-intent-primary {
        background-color: $standard-blue;
    }

    .bp3-menu-item {
        border-radius: 0;
    }

    & ~ .bp3-divider {
        margin-left: 0;
    }
}

.date-range-input {
    @mixin prefix($text) {
        content: $text;
        position: absolute;
        z-index: 15;
        left: 5px;
        top: 0;
        bottom: 0;
        line-height: $control-height;
    }

    .bp3-control-group > :first-child,
    .bp3-control-group > :first-child .bp3-input {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-right: 0 !important;
    }

    .bp3-control-group > :last-child,
    .bp3-control-group > :last-child .bp3-input {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-left: 0 !important;
    }

    .bp3-control-group {
        position: relative;
    }

    .bp3-control-group > :first-child::before {
        @include prefix("с");
    }

    .bp3-control-group > :last-child::before {
        @include prefix("по");
    }

    .bp3-input {
        text-align: center;
    }

    &.focused {
        .bp3-input {
            box-shadow: none;
            border-color: $black;
        }
    }
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

.page {
    color: $planr-font-color;
    position: relative;
    width: 98%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0 $standard-padding 0 $standard-padding;
    background-color: $light-planr-gray;
    :global {
        .bp3-form-group {
            margin-bottom: 0;
        }

        .loader {
            z-index: 10;
        }

        .user-filter {
            display: inline-block;
            margin-left: $standard-padding;
        }
    }

    .reports {
        margin: 26px;

        .reportUnitSpending {
            width: 500px;
            .reportLabel {
                margin-bottom: 16px;
            }
            .dates {
                display: flex;
                .date {
                    margin-right: 12px;
                    .dateLabel {
                        margin-bottom: 6px;
                    }
                    .datePicker {
                    }
                }
            }

            .orders {
                > div {
                    margin-bottom: 6px;
                }
                margin-top: 8px;
                margin-bottom: 8px;
            }

            .selectedOrders {
                max-height: 200px;
                overflow-y: auto;
                border: 2px solid rgb(228, 228, 228);
                padding: 7px;
                border-radius: 4px;
                margin-top: 8px;
                margin-bottom: 8px;

                .orderRow {
                    display: flex;
                    align-items: center;

                    .orderRowLabel {
                        padding-left: 10px;
                        width: 90%;
                    }
                }
            }

            .groupBy {
                margin-top: 8px;
                margin-bottom: 8px;
                > span {
                    display: block;
                    margin-bottom: 6px;
                }
                .groupByRow {
                    display: flex;
                }
            }
        }
    }
}

@import 'modules/common/components/variables.scss';
@import 'modules/root/styles/theme/blueprint/variables.scss';
@import "modules/root/styles/planr/planr-scrolls.scss";

.details {
    width: 800px;
    max-height: 700px;
    min-height: 700px;

    .row {
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        min-height: $control-height;
        padding: 5px 0.5rem 5px 0;
        border-bottom: 1px solid rgba(164, 177, 188, 0.4);
        width: 100%;

        &:first-of-type {
            border-top: none;
        }
    }

    :global {
        .bp3-dialog-body {
            @include planr-scoll();
            overflow-x: hidden;
        }

        .search {
            margin-bottom: $standard-padding;
        }
    }

    .error {
        color: $red2;
    }

    .checked {
        path {
            fill: $success-green !important;
        }
    }

    .iconCell {
        width: 40px;
        justify-content: center;
    }

    .name {
        white-space: normal;
        text-overflow: ellipsis;
        display: inline-block;
        flex: 1 1;
        padding-right: $standard-padding;
    }

    .description {
        white-space: normal;
        text-overflow: ellipsis;
        display: inline-block;
        flex: 1 1;
    }
}

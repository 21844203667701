@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";

$h: 70px;

.scroller {
    overflow-y: auto;
    max-height: 350px;
}

.noData {
    position: absolute;
    width: 100%;
    text-align: center;
    top: ($h/2) - $font-size-standard;
}

:global {
    .block-header {
        padding-left: 10px;
        padding-right: 10px;

        .caret {
            left: auto;
            right: 10px;
        }
    }

    .bp3-collapse-body {
        min-height: $h;
    }
}

.row:nth-child(odd) {
    background-color: #f7f9fa;
}
.row {
    align-items: center;
    height: 37px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "GothamPro", Arial, sans-serif;
    color: $standard-planr-darkblue;
    font-size: 14px;
    display: flex;

    .inventory {
        width: 50px;
        min-width: 50px;
        padding: 0 10px 0 0;
    }

    .name {
        width: 270px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .hours {
        width: 170px;
        min-width: 70px;
        padding: 0 5px 0 5px;
        text-align: right;
    }

    .money {
        width: 100px;
        min-width: 100px;
        padding: 0 5px 0 5px;
        font-weight: bold;
    }
}

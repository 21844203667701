::-webkit-scrollbar {
    width: $scroll-bar;
    height: $scroll-bar;
    background: transparent;
}

::-webkit-scrollbar-track {
    border-radius: $scroll-bar;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: $scroll-bar;
    background-color: transparentize($gray4, 0.5);

    &:hover {
        background-color: $gray4;
    }
}

.orange-scroll::-webkit-scrollbar-thumb {
    background-color: transparentize($standard-orange, 0.1);

    &:hover {
        background-color: $standard-orange;
    }
}

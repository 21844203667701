@import "modules/common/components/variables.scss";
@import "./common";

$pad: 10px;

.calendar {
    background-color: $standard-planr-gray-color;
    border-radius: 4px;
    max-width: $c-width + $c-padding * 2 + 16px;
    color: rgba(0, 39, 61, 0.5);

    .header {
        display: flex;
        align-items: center;
        min-height: 38px;
        width: 100%;
        background-color: #f7f9fa;

        :global {
            .period-slider_value {
                font-weight: normal;
            }
        }
    }

    .tasks {
        width: $c-width + $c-padding * 2;
        padding: $c-padding;
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 8px;
        height: 649px;
        padding-top: 15px;
    }
}
.tabButtons {
    width: 300px;
    display: flex;
    margin-top: -10px;
    margin-bottom: 5px;
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

.actualPayment {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
    width: 100%;
    background-color: $light-planr-gray;
    border-radius: $planr-border-radius;
    box-shadow: 0px 2px 4px 0px rgba(34, 60, 80, 0.2);

    .name {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.actualPayment + .actualPayment {
    margin-top: $standard-padding/2;
}

.paymentDate,
.paymentSum {
    padding: 0 5px;
}

.paymentComment {
    padding: 5px;
}

.paymentDate {
    width: 100px;
    text-align: left;
    padding-bottom: 0;
}

.paymentSum {
    min-width: 130px;
    text-align: center;
    padding-bottom: 0;

    :global {
        .planr-tag {
            width: 100%;
        }
    }
}

@import "modules/common/components/variables.scss";

.page {
    flex-direction: column;
    padding: 0;
    position: relative;
    overflow: hidden;
    padding-top: 24px;

    :global {
        .hidden {
            display: none;
        }
    }
}

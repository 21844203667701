.bp3-datepicker {
    .DayPicker-Month {
        width: 230px;
    }

    .bp3-timepicker .bp3-timepicker-input {
        color: $standard-planr-darkblue;
        font-family: Gotham Pro, Arial, Helvetica, sans-serif;
    }
    .bp3-datepicker-day-wrapper {
        color: $standard-planr-darkblue;
        font-family: Gotham Pro, Arial, Helvetica, sans-serif;
        margin: 0px;
    }
    .bp3-datepicker-month-select {
        .bp3-icon-double-caret-vertical {
            right: 17px !important;
        }
    }
}

@import "./_common.scss";

.rowDashboard {
    margin-left: 14px;
    width: 100%;
    background-color: #fff;
    padding: 0px 16px;

    border-bottom: $border;
    align-items: center;
    display: flex;

    .left {
        width: 50%;
        .userName {
            color: $standard-planr-darkblue;
            font-size: 16px;
            font-weight: 400;
            margin-top: 0px;
        }
    }
    .right {
        margin-left: 100px;
    }
}
.dashboard {
    display: flex;
    width: 373px;
    position: relative;
    .avgHours {
        position: absolute;
        height: 30px;
        top: 0px;
        width: 3px;
        background-color: #005586;
        cursor: help;
        z-index: 1;
        :global {
            .bp3-popover-target {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.tableHeader {
    font-size: 14px;
}

.worked {
    background-color: #fbb916;
    height: 30px;

    align-items: center;
    justify-content: center;
    display: flex;

    :global {
        .bp3-popover-target {
            width: 100%;
            height: 100%;
        }
    }
}

.workedIllness {
    background-color: #cc99d8;
    height: 30px;

    align-items: center;
    justify-content: center;
    display: flex;

    :global {
        .bp3-popover-target {
            width: 100%;
            height: 100%;
        }
    }
}

.vacation {
    background-color: #1dd278;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;

    :global {
        .bp3-popover-target {
            width: 100%;
            height: 100%;
        }
    }
}

.missing {
    background-color: #d76c36;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;

    :global {
        .bp3-popover-target {
            width: 100%;
            height: 100%;
        }
    }
}

.illness {
    background-color: $planr-lightblue-color;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;

    :global {
        .bp3-popover-target {
            width: 100%;
            height: 100%;
        }
    }
}

.tableRow {
    display: flex;
    align-items: center;
    color: $standard-planr-darkblue;
}

.tableHeader {
    font-weight: bold;
}
.hours {
    width: 150px;
}
.money {
    width: 270px;
}
.percents {
    width: 100px;
}
.cost {
    width: 190px;
}
.plan {
    color: #9ba9b1;
}

.sliceHelper {
    display: block;
    width: 100%;
    height: 100%;
}

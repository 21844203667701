@import "modules/common/components/variables.scss";

.spendings {
    .columnsWrapper {
        display: flex;
        flex-direction: row;
    }

    .plan {
        width: 100%;
    }
}
.objects {
    width: 100%;

    position: relative;
}
.planTable {
    padding-top: $standard-padding;
    padding-left: $standard-padding;
    display: flex;
    flex-direction: column;
}

// .row {
//     width: 100%;
//     height: $control-height;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     margin-bottom: 12px;

//     .employer,
//     .daysCount {
//         margin-right: 20px;
//     }

//     .employer {
//         min-width: 360px;
//         max-width: 360px;
//     }

//     .daysCount {
//         min-width: 120px;
//         max-width: 120px;

//         :global {
//             .bp3-input-group {
//                 width: 120px;
//             }

//             .bp3-form-group {
//                 margin-bottom: 0;
//             }
//         }
//     }

//     .money {
//         min-width: 220px;
//         max-width: 220px;

//         :global {
//             .bp3-label {
//                 display: none;
//             }

//             .bp3-form-group {
//                 margin-bottom: 0;
//             }
//         }
//     }

//     & + .row {
//         margin-top: 8px;
//     }
// }
.tripSpendingsHeader {
    left: 460px;
    position: absolute;
    top: 22px;
    color: $standard-planr-darkblue;
}
.spendingsRows {
    margin: 10px;
    border-radius: 4px;
    margin-bottom: 6px;
    background-color: $standard-planr-gray-color;

    .row {
        display: flex;
        background-color: #fff;
        border-radius: 4px;
        margin: 10px;
        padding: 16px;
        .rowLeft {
            width: 570px;
        }
        .mainHeader {
            margin-bottom: 10px;
        }
        .secondHeader {
            font-size: 12px;
        }
        .leftTable {
            display: flex;
        }
        .employ {
            width: 270px;
        }
        .hours {
            width: 150px;
        }

        .money {
            width: 250px;
        }

        .planSum {
            width: 200px;
            padding-left: 5px;
        }
        .planDate {
            width: 200px;
            padding-left: 5px;
        }
    }
}

.spendingsSpan {
    margin-bottom: 20px;
    margin-left: 22px;
    font-size: 16px;
}

:global {
    .order_own_spending_employer_select {
        width: 360px;
    }
}

.page {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #edf1f2;
    .wrapper {
        width: 330px;
        padding: 15px 20px;
        padding-bottom: 25px;
        border-radius: 4px;
        background-color: #fff;
    }

    :global {
        .bp3-inline .bp3-label {
            min-width: 70px;
        }
    }
}

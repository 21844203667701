@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";

.page {
    background-color: #f7f9fa;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    position: relative;

    .tools {
        display: flex;
        padding-right: $standard-padding;
        align-items: center;

        .right {
            position: absolute;
            align-items: center;
            display: flex;
            right: 40px;
            top: 24px;
        }
    }
}

.title {
    display: flex;
    align-items: center;

    button {
        margin-left: auto;
        margin-right: $standard-padding;
    }
}

.documentSelector {
    display: flex;
    width: 100%;
    margin-left: 24px;
    margin-top: 16px;
    margin-bottom: 24px;
}

.spanDocumentSelector {
    margin-left: 16px;
    color: $standard-planr-darkblue;
    margin-right: 10px;
    width: 295px;
    margin-top: 5px;
}

.userAccess {
    :global {
        .overwritten {
            background-color: rgba(54, 172, 215, 0.4);
        }
    }
}

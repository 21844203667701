@import "../vars.scss";

.object {
    padding: 12px 0px;
    background-color: $white;
    width: 1540px;
    transition: 0.2s ease-in;
    color: $standard-planr-darkblue;
    border-radius: 4px;
    margin-bottom: 8px;

    .label {
        display: flex;
        position: relative;
        font-size: $font-size;
        padding-left: $padding;
        font-size: 16px;
        .labelWrapper {
            width: calc(100% - 100px);
            .sumNumbersObj {
                //  margin-top: 45px;
                position: relative;
                width: 100%;
                height: 24px;
            }
            .labelContent {
                //white-space: nowrap;
                display: block;
                //width: 100%;
                //overflow: hidden;
                // text-overflow: ellipsis;
            }
        }
    }

    .collapser {
        margin-right: $collapser-padding;
    }

    .outsourcers {
        margin-top: $standard-padding;
    }

    :global {
        .order-payments {
            width: 400px;
        }

        .outsourced-spending-row {
            margin-bottom: $standard-padding;
        }

        .action-icon {
            margin-left: $cross-padding;
        }
    }
}

.spendingBody {
    :global {
        .bp3-form-group {
            margin: 0;
        }
    }
}

.row {
    display: flex;
    flex-direction: row;
    padding: 16px 16px;
    background-color: $white;

    &:not(.row:first-child) {
        border-top: 1px solid $standard-planr-gray-color;
    }

    .left {
        display: flex;
        flex-direction: column;
    }

    .one {
        display: flex;
        flex-direction: row;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
    }

    .actuals,
    .plan {
        margin-left: $standard-padding * 3;

        td {
            vertical-align: middle;
        }
    }

    .actuals {
        .table {
            min-width: 190px;
            padding-top: 14px;
            .headerRow {
                height: 5px;
            }

            .sumTop {
                max-width: 232px;
                width: 232px;
                font-size: 12px;
                height: 12px;
                padding: 0px;
                padding-right: 12px;
            }

            .dateTop {
                max-width: 100px;
                width: 132px;
                font-size: 12px;
                height: 12px;
                padding: 0px;
            }

            .sum {
                max-width: 232px;
                width: 232px;
                font-size: 12px;
                height: 12px;
                padding-right: 12px;
                padding-bottom: 13px;
            }

            .date {
                max-width: 232px;
                width: 232px;
                font-size: 12px;
                height: 12px;
                padding-bottom: 13px;
            }
            td {
                width: 30%;
            }
        }
    }

    .plan {
        .table {
            min-width: 320px;
            padding-top: 14px;
        }
        .headerRow {
            height: 5px;
        }

        .sumTop {
            max-width: 232px;
            width: 232px;
            font-size: 12px;
            height: 12px;
            padding: 0px;
            padding-right: 12px;
        }

        .dateTop {
            max-width: 232px;
            width: 232px;
            font-size: 12px;
            height: 12px;
            padding: 0px;
        }
        .sum {
            max-width: 232px;
            width: 232px;
            font-size: 12px;
            height: 12px;
            padding-right: 12px;
            padding-bottom: 13px;
        }

        .date {
            max-width: 232px;
            width: 232px;
            font-size: 12px;
            height: 12px;
            padding-bottom: 13px;
        }
    }

    .taskState {
        margin-right: 24px;

        path {
            fill: $standard-gray !important;
        }

        .active {
            path {
                fill: $standard-blue !important;
            }
        }

        .success {
            path {
                fill: $success-green !important;
            }
        }

        :global {
            .bp3-icon {
                margin-bottom: 7px;
            }
        }
    }

    .orderSum {
        display: flex;
        flex-direction: row;
        margin-top: 16px;

        label {
            font-size: 12px;
        }

        > div + div {
            margin-left: 15px;
        }

        > div {
            flex: 1 auto;
        }

        :global {
            .planr-default-input {
                margin-top: 5px;
            }
        }
    }

    .outsourcer {
        margin-top: 12px;
        display: flex;
        flex-direction: column;

        label {
            font-size: 12px;
            margin-bottom: 5px;
        }

        :global {
            .bp3-icon {
                display: flex;
                cursor: pointer;
                margin-left: $cross-padding;
            }

            .selected-option {
                width: 100%;
            }
        }
    }

    .orders {
        margin-top: $padding;
    }

    :global {
        .order-base {
            max-width: 476px;
        }

        .order-files-list {
            width: 476px;
            margin-top: 12px;
        }
    }
}

:global {
    .spendings-outsourserselect-dropdown {
        width: auto !important;

        .bp3-menu {
            overflow-y: visible;
            max-height: 190px;
            overflow-x: hidden;
        }

        .bp3-menu-item {
            width: 472px;
        }
    }

    .spendings-outsourserselect-dropdown {
        .bp3-text-overflow-ellipsis {
            white-space: normal;
        }
    }
}

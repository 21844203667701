@import "modules/common/components/variables.scss";

.documents {
    display: flex;

    .column {
        flex: 1 auto;
    }

    .column + .column {
        margin-left: 5px;
    }

    .file {
        white-space: nowrap;
        overflow: hidden;
        color: $standard-planr-darkblue;
        font-size: 12px;
        cursor: pointer;
        background-color: #e4e9eb;
        border-radius: 4px;
        padding: 12px;
        width: 154px;
        transition: 0.2s ease-in;

        &:hover {
            background-color: rgba(234, 86, 30, 0.1);
        }
    }
}

$height: 50px;
$button: 38px;

.toolbox {
    background-color: #323639;
    height: $height;
    padding: 0 29px 0 23px;
    display: flex;
    align-items: center;

    .name {
        display: inline-block;
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        height: $height;
        line-height: $height;
        color: #F1F1F1;
        font-weight: bold;
    }

    .button {
        width: $button;
        height: $button;
        cursor: pointer;
        display: inline-block;

        & + .button {
            margin-left: 10px;
        }
    }

    .buttons {
        text-align: right;
        margin-left: auto;
    }

    .download {
        background-image: url('/images/toolbox-download.png');

        &:hover {
            background-image: url('/images/toolbox-download-active.png');
        }
    }

    .print {
        background-image: url('/images/toolbox-print.png');

        &:hover {
            background-image: url('/images/toolbox-print-active.png');
        }
    }
}

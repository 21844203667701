@import "modules/common/components/variables.scss";

.ipd {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow: auto;
    padding-right: 16px;

    .add {
        height: 43px;
        display: flex;
        flex-direction: row;
        align-items: center;

        cursor: pointer;

        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: $standard-padding;

            :global {
                .action-icon {
                    margin-right: $standard-padding * 0.6;
                }
            }
        }
    }

    :global {
        .copy {
            margin-left: auto;
        }
    }
}
.label {
    color: $standard-planr-darkblue;
    margin-bottom: 3px;
}
.buttons {
    position: fixed;
    bottom: 0;
    right: 0;
    width: auto;
    display: flex;

    background-color: #fff;
    width: 100%;

    .buttonsLeft {
        margin-left: 330px;
    }
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";

.grid {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    flex-direction: column;
    padding-right: $standard-padding;

    :global {
        .fired .ag-cell-value {
            color: rgba($color: $standard-planr-darkblue, $alpha: 0.5);
        }
    }
}

.hidden {
    display: none;
}

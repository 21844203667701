@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";

$ROW_HEIGHT: $control-height + 5px;

.inputs {
    display: flex;
    flex-direction: row;

    .sum {
        display: flex;

        .final {
            margin-left: auto;
        }
    }

    :global {
        .bp3-form-group {
            min-width: 250px;
        }
        .money-gray {
            color: rgba(0, 39, 61, 0.4);
        }
        .bp3-form-group + .bp3-form-group {
            margin-left: $standard-padding;
        }
    }
}

$table: 650px;

.columns {
    color: $standard-planr-darkblue;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    h2 {
        font-weight: 400;
    }
    & > div {
        width: 50%;
        min-width: $table + 10px;
    }
}

.department {
    max-width: 720px;
    min-width: 720px;
    margin-bottom: $standard-padding / 2;
    padding: $standard-padding / 2;
    margin-left: 1px;

    h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0;
        font-weight: 400;
    }

    :global {
        .ag-layout-auto-height .ag-center-cols-clipper,
        .ag-layout-auto-height .ag-center-cols-container {
            min-height: initial;
        }

        td.righted {
            text-align: right !important;
        }
    }
}

.row {
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    min-height: $ROW_HEIGHT;

    &:first-of-type {
        border-top: $light-border;
    }

    &.header {
        > div {
            font-weight: bold;
        }
    }

    &.summary {
        background-color: $light-gray;
        margin-top: $standard-padding;

        > div {
            font-weight: bold;
        }

        .user {
            position: relative;
            min-height: $ROW_HEIGHT;
        }

        .departmentName {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0;
            min-height: $ROW_HEIGHT;
            line-height: $ROW_HEIGHT;
            width: 500px;
        }
    }

    &.fired {
        background-color: $planr-disabled-color;
        color: rgba(0, 39, 61, 0.8);
    }

    .user {
        min-width: 120px;
        max-width: 120px;
    }

    .salary {
        min-width: 90px;
        max-width: 90px;
    }

    .salaryFactor {
        min-width: 40px;
        max-width: 40px;
        text-align: center;
    }

    .hours {
        min-width: 70px;
        max-width: 70px;
        text-align: center;
    }

    .factor {
        min-width: 100px;
        max-width: 100px;
    }

    .percent {
        min-width: 100px;
        max-width: 100px;
    }

    .quality {
        min-width: 70px;
        max-width: 70px;

        :global {
            .bp3-checkbox {
                text-align: center;
                margin: 0;
            }
        }
    }

    .sum {
        min-width: 100px;
        max-width: 100px;
    }

    .assing {
        min-width: 100px;
        max-width: 100px;
        display: flex;
    }

    .alert {
        color: $standard-orange;
    }
}

.payments {
    .header {
        display: flex;
        align-items: center;
    }

    .remains {
        margin-left: auto;
        display: inline-block;
    }

    .period {
        padding-left: $standard-padding;
        min-width: calc(110px + #{$standard-padding});
        display: flex;
        justify-content: center;
    }

    :global {
        .bp3-popover-content {
            width: 290px;
            padding: 1rem !important;
        }

        .bp3-button {
            min-width: 100px;
        }
    }
}

.engineerDetails {
    width: 150px;

    > div {
        display: flex;
    }

    :global {
        .money {
            margin-left: auto;
        }
    }
}

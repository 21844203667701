@import 'modules/root/styles/theme/blueprint/variables.scss';

@mixin planr-scoll($scroll-bar: 7px) {
    &::-webkit-scrollbar {
        width: $scroll-bar;
        height: $scroll-bar;
        background: transparent;
    }

    &::-webkit-scrollbar-track {
        border-radius: $scroll-bar;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $scroll-bar;
        background-color: transparent;

        &:hover {
            background-color: $gray4;
        }
    }

    &:hover::-webkit-scrollbar-thumb {
        background-color: transparentize($gray4, 0.5)
    }
}

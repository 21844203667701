.page {
    width: 98%;
    padding-left: 16px;
    background-color: #f5f7f8;
    .actions {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
    }
    .right {
        display: flex;
        margin-right: 32px;
        align-items: center;
    }
    padding-top: 24px;
    .addButton {
        position: absolute;
        border-radius: 120px;
    }
}

.title {
    display: flex;
    align-items: center;

    .showAccess {
        margin-left: auto;
    }
}

.userAccess {
    :global {
        .overwritten {
            background-color: rgba(54, 172, 215, 0.4);
        }
    }
}

.tabButtons {
    position: absolute;
    display: flex;
    align-items: center;
    left: 450px;
}

.tabButtons2 {
    position: absolute;
    display: flex;
    align-items: center;
    left: 450px;
    top: 70px;
}

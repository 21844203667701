@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/root/styles/planr/variables.scss";

.page {
    position: relative;
    width: 98%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0 $standard-padding 0 $standard-padding;
    background-color: $light-planr-gray;
    :global {
        .bp3-form-group {
            margin-bottom: 0;
        }

        .loader {
            z-index: 10;
        }

        .user-filter {
            display: inline-block;
            margin-left: $standard-padding;
        }
    }
}

.modeSelector {
    min-height: $pt-input-height;
    line-height: $pt-input-height;
}

.filters {
    margin-top: $standard-padding;
    margin-bottom: -10px;
    display: flex;
    flex-direction: column;

    .left,
    .right {
        display: flex;
        margin-right: 16px;
        align-items: center;
    }

    .left,
    .right {
        > svg {
            width: 21px;
            height: 21px;
            margin-right: $standard-padding;
        }
    }

    .left {
        position: relative;
    }

    .center {
        display: flex;
        margin-right: 320px;
        align-items: center;
        justify-content: center;
    }

    .override {
        display: flex;
        position: absolute;
        align-items: center;
        top: -65px;
    }

    .right {
        justify-content: flex-end;
    }

    .periodSlider {
        display: flex;
        position: relative;
        justify-content: center;
        margin-bottom: $standard-padding/2;

        .actions {
            position: absolute;
            right: 0;
            display: flex;
            justify-content: center;
            margin-top: 10px;
        }

        :global {
            .zoomer {
                margin-left: $standard-padding;
            }
        }
    }

    .cellModeCelector {
        width: 170px;
        margin-left: 16px;
    }

    .tools {
        display: flex;
        min-height: $control-height;

        .activeIcon {
            path {
                fill: $standard-blue !important;
            }
        }
    }
}

.zoomArea {
    transform-origin: top left;
    align-items: flex-start;
    align-self: flex-start;
    width: 100%;
    overflow-y: auto;
    margin-top: 10px;

    :global {
        .department-block:first-of-type .block-header {
            margin-top: 0;
        }
    }
}

.nodata {
    margin: auto;
}

.infoButton {
    position: absolute;
    top: -48px;
    left: 50px;
}
.infoPopup {
    padding: 16px;
    color: $standard-planr-darkblue;
    font-weight: 200;
}

.bp3-dialog {
    background-color: $white;
    border-radius: $standard-radius;
}

.bp3-overlay-backdrop {
    background-color: transparent;
}

.bp3-overlay-backdrop.standard {
    background: rgba(45, 53, 65, 0.7);
}

.bp3-dialog-header {
    min-height: 50px;
}

.figma-dialog {
    padding-bottom: $figma-dialog-padding;

    .bp3-dialog-header {
        box-shadow: none;
        min-height: 80px;
        padding-left: $figma-dialog-padding;
        padding-right: 20px;
        .bp3-heading {
            font-family: "GothamPro", Arial, sans-serif;
            color: $standard-planr-darkblue;
        }

        font-size: 18px;

        .bp3-button {
            padding: 0;
        }
    }

    .bp3-dialog-body {
        margin-top: 0;
        margin-left: $figma-dialog-padding;
        margin-right: $figma-dialog-padding;
    }

    .bp3-dialog-footer {
        margin-left: $figma-dialog-padding;
        margin-right: $figma-dialog-padding;
    }
}

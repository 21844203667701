@import "modules/root/styles/planr/planr-scrolls.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

.columns {
    background-color: white;
    padding: $standard-padding;
    max-height: 650px;
    overflow: auto;
    @include planr-scoll();
    @include planr-font();
}

.infoMoved {
    color: $planr-lightblue-color;
    size: 12px;
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";

.block {
    display: flex;
    margin-bottom: $standard-padding;
    padding: 0;
    color: $standard-planr-darkblue;
    font-weight: 300;
    font-family: "GothamPro", Arial, sans-serif;
    width: 1480px;
}

.active {
    cursor: pointer;
}

.wrapper {
    flex: 1 1;
    padding: $pt-grid-size 0;
    position: relative;
}

.row {
    transition: 0.2s ease-in;
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    width: 1480px;

    & > div {
        display: flex;
        align-items: center;
    }

    &.highlighted {
        background-color: $light-gray;
    }

    .iconCell {
        width: 40px;
        justify-content: center;
    }

    .orderNumber {
        width: 150px;
        padding-right: $standard-padding/2;
        text-overflow: ellipsis;
    }

    .workAndInventory {
        width: 200px;
        padding-right: $standard-padding/2;
        text-overflow: ellipsis;
    }

    .own {
        path {
            fill: $neutral-gray !important;
        }
    }

    .outsourced {
        path {
            fill: $success-green !important;
        }
    }

    .description {
        white-space: normal;
        text-overflow: ellipsis;
        display: flex;
        flex: 1 1;
        padding-right: $standard-padding/2;
    }

    .progress {
        width: 60px;
        justify-content: flex-end;
    }

    :global {
        .bp3-input-group {
            width: 100%;
        }

        input {
            padding: 0 5px !important;
        }
    }
}
.rowTaskBlock {
    width: 1480px;
    display: flex;
    flex-direction: column;
}

.commentSupplier {
    white-space: normal;
    text-overflow: ellipsis;
    overflow-y: scroll;
    display: flex;
    padding-right: 20px;
    max-height: 72px;
    width: 400px;
}
.rowTask {
    display: flex;
    width: 100%;
    .leftColumn {
        display: flex;
        margin-right: 16px;
        width: 710px;
        flex-direction: column;

        .rowTaskLeft {
            display: flex;
            border-radius: 4px;
            flex-direction: column;
            position: relative;
            background-color: #fff;
            width: 100%;
            margin-bottom: 10px;
            .rowTaskHeader {
                font-size: 16px;
                display: flex;
                font-weight: 400;
                display: flex;
                width: 100%;
                padding: 5px 7px;
                position: relative;
                height: 50px;
                align-items: center;
                vertical-align: middle;

                .rowTaskHeaderBtns {
                    position: absolute;
                    display: flex;
                    top: 5px;
                    right: 24px;
                }
            }
            .rowTaskLeftItemHeader {
                font-size: 14px;
                font-weight: 400;
                display: flex;
                width: 100%;
                padding: 5px 7px;
            }
            .rowTaskLeftItem {
                font-size: 14px;
                font-weight: 300;
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 15px;
                padding: 5px 7px;
            }
        }
    }
}

.showDialogTask {
    color: $standard-planr-darkblue;
}
.popupVersions {
    display: flex;
    width: 100%;
    max-height: 400px;
    padding: 10px;
    overflow-y: scroll;
    color: $standard-planr-darkblue;
}
.fileName {
    overflow: hidden;

    &:hover {
        text-decoration: underline;
    }
}
.fileBlock {
    font-size: 14px;
    height: 40px;
    cursor: pointer;
    width: 320px;
    transition: 0.2s ease-in;

    &:hover {
        border-color: #ad441d;
    }
}
.rightColumn {
    display: flex;
    width: 740px;
    flex-direction: column;
}
.rowTaskLeft {
    padding: 20px;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    width: 100%;
    // margin-right: 16px;

    background-color: #fff;
    margin-bottom: 10px;
    .rowTaskHeader {
        font-size: 16px;
        display: flex;

        font-weight: 400;
        display: flex;
        width: 100%;
        padding: 5px 7px;
        position: relative;
        height: 50px;
        align-items: center;
        vertical-align: middle;
        .rowTaskHeaderItem {
            display: flex;
            justify-content: center;
        }
        .rowTaskHeaderBtns {
            position: absolute;
            display: flex;
            top: 5px;
            right: 24px;
        }
    }
    .rowTaskLeftItemHeader {
        font-size: 14px;
        font-weight: 400;
        display: flex;
        width: 100%;
        padding: 5px 7px;
        margin-bottom: -18px;
    }
    .rowTaskLeftItem {
        font-size: 14px;
        font-weight: 300;
        display: flex;
        width: 100%;

        padding: 5px 7px;
    }
}

.rowTaskRight {
    padding: 20px;

    border-radius: 4px;

    width: 60%;
    background-color: #fff;
    margin-bottom: 10px;
    .rowTaskHeader {
        font-size: 16px;
        display: flex;
        justify-content: center;
        font-weight: 400;
        display: flex;
        width: 100%;
        padding: 5px 7px;
        position: relative;
        height: 50px;
        align-items: center;
        vertical-align: middle;
    }
    .rowTaskRightContent {
        display: flex;
        flex-direction: row;
        .rowTaskRightItem {
            width: 60%;
            margin-right: 32px;
            .rowTaskLeftItemHeader {
                font-size: 14px;
                font-weight: 400;
                display: flex;
                width: 100%;
                padding: 5px 7px;
            }
            .rowTaskLeftItem {
                font-size: 14px;
                font-weight: 300;
                display: flex;
                width: 100%;
                flex-direction: column;
            }
            .rowTaskHeader {
                font-size: 16px;
                display: flex;
                justify-content: center;
                font-weight: 400;
                display: flex;
                width: 100%;
                padding: 5px 7px;
                position: relative;
                height: 50px;
                align-items: center;
                vertical-align: middle;

                .rowTaskHeaderBtns {
                    position: absolute;
                    display: flex;
                    top: 5px;
                    right: 24px;
                }
            }
        }

        .rowTaskLeftItemMain {
            width: 40%;
            .rowTaskLeftItemHeader {
                font-size: 14px;
                font-weight: 400;
                display: flex;
                width: 100%;
                padding: 5px 7px;
            }
            .rowTaskLeftItem {
                font-size: 14px;
                font-weight: 300;
                display: flex;
                width: 100%;

                //padding: 5px 7px;

                .rowTaskLeftChild {
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                }
            }
        }
    }
}
.edit {
    width: 50px;
}
.employer {
    width: 170px;
    padding-left: 5px;
    white-space: normal;
    text-overflow: ellipsis;
}
.taskFile {
    width: 160px;
}
.taskFileMiddle {
    display: flex;
    align-items: center;
    margin-left: 15px;
}
.taskFileLeft {
    display: flex;
    align-items: center;
    height: 45px;
    width: 565px;
}

.taskFileRight {
    display: flex;
    width: 790px;
    margin-left: 50px;
}

.taskFileWide {
    width: 210px;
}
.rowFile:nth-child(even) {
    background-color: #f7f9fabe;
}
.rowFile {
    display: flex;
    width: 100%;

    padding: 15px;
}
.date {
    width: 80px;
}

.time {
    font-size: 12px;
}
.version {
    width: 100px;
    margin-left: 220px;
    text-overflow: ellipsis;
}

.download {
    width: 100px;
}

.commentHeader {
    width: 180px;
}
.commentFile {
    width: 200px;
    white-space: normal;
    text-overflow: ellipsis;
    overflow-x: scroll;
}

.comment {
    width: 180px;
    white-space: normal;
    text-overflow: ellipsis;
    overflow-x: scroll;
    margin-left: 260px;
}
.remove {
    width: 30px;
}
.accepted {
    width: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 30px;
    top: 55px;
    font-weight: 400;
}
.details {
    width: 816px;
    height: 650px;

    color: $standard-planr-darkblue;
    font-weight: 300;
    font-family: "GothamPro", Arial, sans-serif;

    .row {
        padding-left: 0;

        &:first-of-type {
            border-top: none;
        }
    }

    :global {
        .bp3-dialog-body {
            overflow-y: auto;
            overflow-x: hidden;
            color: $standard-planr-darkblue;
            font-weight: 300;
            font-family: "GothamPro", Arial, sans-serif;
        }
    }

    .error {
        color: $red2;
    }
}
.commentField {
    display: flex;
    flex-direction: column;
}

.commentText {
    white-space: normal;
    text-overflow: ellipsis;
    overflow-x: scroll;
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";

$h: 70px;

.widget {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .scroller {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        max-height: 250px;
        background-color: #fff;
    }

    .noData {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 0px;
    }

    :global {
        .block-header {
            padding-left: 10px;
            padding-right: 10px;

            .caret {
                left: auto;
                right: 10px;
            }
        }

        .bp3-collapse-body {
            min-height: $h;
        }
    }
}

.row {
    height: 36px;
    line-height: $font-size-small + 2px;
    border-bottom: $light-border;
    font-size: $font-size-small;
    font-family: GothamPro, Arial, sans-serif;
    color: $standard-planr-darkblue;
    display: flex;

    .inventory {
        width: 140px;
        min-width: 40px;
        padding: 6px;
    }

    .name {
        flex: 1 auto;
        width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 6px;
    }

    .date {
        width: 100px;
        min-width: 100px;
        padding: 6px;
    }
    .headerTableTop {
        background-color: $standard-planr-gray-color;
        font-weight: bold;
        font-size: 14px;
    }
}

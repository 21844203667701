@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/planr-scrolls.scss";
@import "modules/root/styles/planr/fonts.scss";
.details {
    :global {
        .bp3-dialog-body {
            display: flex;

            & > div {
                flex: 1 0;
            }
        }

        .bp3-inline .bp3-label {
            min-width: 150px;
        }

        .bp3-form-content {
            width: 100%;
            max-width: 275px;
        }

        .bp3-form-group {
            margin-bottom: $modal-form-input-padding;
        }

        .hidden {
            display: none;
        }
    }
}

.files {
    display: flex;
    margin-left: $standard-padding;
    min-width: 400px;
    max-width: 400px;

    :global {
        .bp3-card {
            flex: 1 auto;
            margin-bottom: $modal-form-input-padding;
            padding-bottom: 0;
            max-height: 735px;
            background-color: $standard-planr-gray-color;
            overflow: auto;
            @include planr-scoll();
        }
    }
}

.checkers {
    display: flex;
    align-items: center;
}
.childrenRow:nth-child(odd) {
    background-color: rgb(243, 243, 243);
}
.childrenRow {
    display: flex;
    height: 25px;
    align-items: center;
    .childrenRowName {
        width: 350px;
    }
    .childrenRowBDay {
        width: 200px;
    }
}

.projects,
.newEmployee {
    display: flex;
    margin-left: $standard-padding;
    min-width: 400px;
    max-width: 400px;
}

.projects {
    :global {
        .own-spendings-widget-scroller {
            background-color: transparent !important;
            max-height: 735px !important;
            flex: 1 auto;
        }
    }
}

.newEmployee {
    flex-direction: column;

    :global {
        .bp3-inline .bp3-label {
            min-width: 70px;
        }
    }
}

.credentials {
    display: flex;
    flex-direction: row;

    & > div {
        width: 100%;
    }

    .printCredentials {
        margin-left: 5px;
        max-width: 120px;
        display: flex;

        button {
            text-align: center;
            //  height: calc(100% - #{$modal-form-input-padding});
        }
    }
}

.addChildBtn {
    margin: 30px auto 0px;
}
.avatar {
    margin-top: -5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    .avatarText {
        font-size: 12px;
        margin-right: 130px;
        color: $planr-font-color;
    }
}

.avatarLoad {
    color: $planr-font-color;
}
.avatarImage {
    width: 55px;
    height: 55px;
    border-radius: 50px;
    object-fit: cover;
}
.bigAvatarContainer {
    padding: 10px;
    .bigAvatar {
        width: 120px;
        height: 120px;
        border-radius: 4px;
        object-fit: cover;
    }
}

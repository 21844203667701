@import "modules/common/components/variables.scss";

.finance {
    display: flex;
    flex: 1 1;
    flex-direction: column;

    .inner {
        display: flex;
        flex-direction: column;
        margin-right: 16px;
        .innerRow {
            display: flex;
            flex-direction: row;
        }
    }

    .cell {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        width: 50%;
        padding: 0 0 $standard-padding 0;

        &.followed {
            margin-right: 16px;
        }

        &.completions {
            min-width: 575px;
        }

        &.payments {
            min-width: 450px;
        }
    }

    :global {
        .money {
            font-size: 100%;
        }

        .hidden {
            display: none;
        }

        .order-timeline-wrapper {
            margin-bottom: $standard-padding;
        }
    }
}

.tableContainer {
    width: 100%;

    .addButton {
        color: $white;
        margin-left: $standard-padding * 3;
    }

    .title {
        margin-right: auto;
    }

    .tableHeader {
        td {
            color: $standard-planr-darkblue;
            font-size: 14px;
        }
    }

    tr {
        td {
            height: $control-height;
            vertical-align: middle;
            padding-right: 5px;
        }
    }

    table {
        margin-top: $standard-padding;
        border-collapse: collapse;
        color: $standard-planr-darkblue;
    }

    :global {
        .block-header {
            justify-content: flex-end;
        }

        .bp3-icon-lock {
            path {
                fill: $standard-gray !important;
            }
        }
    }
}

.comment {
    max-width: 210px;
    width: 210px;
}

.completionFile {
    width: 210px;

    > div {
        display: flex;
        align-items: center;
    }
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "./common";

.day {
    width: $day-width;
    min-width: $day-width;
    height: 90px;
    min-height: 90px;
    border-right: 1px solid $standard-planr-gray-color;
    border-bottom: 1px solid $standard-planr-gray-color;
    background-color: #fff;
    padding: 10px;
    z-index: 1;
    transition: 0.2s ease-in;

    position: relative;

    &.isOtherMonth {
        background-color: #e4e8eb;
    }
    &.isDayOff {
        .number {
            font-size: 12px;
            color: #e31818;
        }
    }

    &.isToday {
        .number {
            display: flex;
            width: 22px;
            height: 22px;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 12px;
            background-color: #ea561e;
            border-radius: 1000px;
            z-index: 2;
        }
    }

    &.topLeft {
        border-top-left-radius: $radius;
    }

    &.topRight {
        border-top-right-radius: $radius;
    }

    &.bottomLeft {
        border-bottom-left-radius: $radius;
    }

    &.bottomRight {
        border-bottom-right-radius: $radius;
    }

    .number {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $standard-planr-darkblue;
        font-size: 12px;

        &.other {
            color: rgba(0, 39, 61, 0.4);
        }
    }

    .selection {
        position: absolute;
        top: -2px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border: 2px solid #ea561e;
        border-radius: 4px;
        z-index: 5;
    }
}

.event {
    position: absolute;
    left: -1px;
    right: -1px;
    z-index: 1;

    &.start {
        left: 10px;
        border-top-left-radius: $standard-radius;
        border-bottom-left-radius: $standard-radius;
        display: flex;

        .stripe {
            width: 3px;
            height: calc(100%);
            border-top-left-radius: $standard-radius;
            border-bottom-left-radius: $standard-radius;
            align-self: center;
        }
    }

    &.end {
        right: 10px;
        border-top-right-radius: $standard-radius;
        border-bottom-right-radius: $standard-radius;
    }
}

.name {
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-size: 14px;
    color: $planr-font-color;

    .title {
        @include planr-font("tiny");
    }

    :global {
        .red-icon {
            cursor: pointer;
            position: absolute;
            right: 15px;
        }
    }
}

@import 'modules/common/components/variables.scss';

.workCalendarDialog {
    position: relative;

    :global {
        .dnd-uploader {
            margin-top: $standard-padding;
        }
    }
}

@import "modules/common/components/variables.scss";

.fileList {
    margin-bottom: $standard-padding / 2;

    padding: 5px 10px 5px 10px;
    flex: 1 auto;

    h3 {
        margin-top: 0;
    }

    .uploadedfilesList {
        max-height: 130px;
        overflow: auto;
    }
}

@import 'modules/common/components/variables.scss';

.details {
    :global {
        .bp3-inline .bp3-label {
            min-width: 170px;
        }

        .bp3-form-content {
            width: 100%;
        }

        .bp3-form-group {
            margin-bottom: $modal-form-input-padding;
        }

        .director-name {
            display: none;
        }
    }
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";

$year-width: 170px;
$base-color: #ddd;
.actions {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
}
.right {
    display: flex;
    margin-right: 32px;
    align-items: center;
}

.page {
    flex-direction: column;
    padding: 0;
    position: relative;
    overflow: hidden;
    margin-top: 24px;
    font-family: "GothamPro", Arial, sans-serif;
    color: $standard-planr-darkblue;
    :global {
        .hidden {
            display: none;
        }
    }
}

.add {
    display: flex;
    align-items: center;
}

.years {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: -40px;

    .results {
        position: absolute;
        top: 124px;
        left: 12px;
        z-index: 10;
        font-size: 18px;
    }
    .scroller {
        width: 80%;
        overflow-x: auto;
        display: flex;
        flex-direction: row;
        margin-left: $standard-padding;
    }
}

.yearBlock + .yearBlock {
    margin-left: 8px;
}

.yearBlock {
    font-family: "GothamPro", Arial, sans-serif;
    background-color: $standard-planr-gray-color;
    box-sizing: border-box;
    border-radius: 4px;
    padding: $standard-padding/2;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    width: $year-width;
    min-width: $year-width;
    min-height: 52px;
    color: rgba(0, 39, 61, 0.5);

    position: relative;
    transition: 0.2s ease-in;
    .planSum {
        font-weight: 300;
    }
    .year {
        transition: 0.2s ease-in;
        color: $standard-planr-darkblue;
        transform: rotate(-90deg);
        height: 17px;
        font-weight: bold;
        font-size: 16px;
        margin-left: -10px;
        margin-top: 10px;
    }

    .money {
        font-family: "GothamPro", Arial, sans-serif;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .actual {
            color: $standard-planr-darkblue;
        }
    }

    &.loading {
        background-color: $standard-planr-gray-color;
    }

    &:hover:not(.loading) {
        background-color: #f6e9e4;
        .year {
            color: #ea561e;
        }
    }

    &.active:not(.loading) {
        background-color: #ea561e;
        color: $white;
        .year {
            color: #fff;
        }
        .money .actual {
            color: $white;
        }
    }

    :global {
        .money {
            font-size: $font-size;
        }
    }
}

.totalRow {
    height: 45px;
    margin-bottom: -2px;
    width: 99%;
    position: absolute;
    z-index: 1;
    top: 85px;
    :global {
        .ag-header {
            display: none;
        }

        .ag-cell {
            background-color: #e1f5ed;
        }
    }
}

.totalRow {
    :global {
        .total-top .actual {
            background-color: #cef2e2;
        }
        .total-column .actual {
            background-color: #cef2e2;
        }

        .total-column .plan {
            background-color: #e1f5ed;
        }
    }
}

.totalRow,
.table {
    font-size: 12px;
    margin-top: 16px;
    :global {
        .ag-cell {
            border: 0;
        }
        .ag-row:hover {
            background-color: #f3f3f3;
        }
        .ag-header-viewport,
        .ag-body-viewport,
        .ag-center-cols-viewport {
            background-color: #f5f7f8;
        }

        .ag-cell:not(.overheadType) {
            color: rgba(0, 39, 61, 0.4);
            font-weight: normal;
        }

        .ag-cell-value {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: $standard-planr-darkblue;
        }
        .ag-header-cell-text {
            font-size: 20px;
        }

        .actual,
        .plan {
            padding-right: 11px;
            color: rgba(0, 39, 61, 0.4);
        }

        .actual {
            color: $standard-planr-darkblue;
        }
    }
}

.overhead-results {
    position: absolute;
    top: 10px;
    left: 200px;
    z-index: 2;
}

.table {
    position: relative;
    display: flex;
    flex: 1 1;
    overflow: hidden;
    flex-direction: column;
    padding-right: $standard-padding;

    .undoButton,
    .saveButton {
        margin-left: 2px;
        margin-top: 5px;
        cursor: pointer;
    }

    :global {
        .actual {
            background-color: #f3f3f3;
        }

        .ag-react-container {
            flex-direction: column;
        }
        .ag-header {
            margin-bottom: 43px;
        }

        .plan {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            // .bp3-input {
            //     border: $standard-border !important;
            //     padding: 0 5px 0 5px !important;
            // }
        }

        .total-column,
        .total-column .actual {
            background-color: #cef2e2;
        }

        .total-column .plan {
            background-color: #e1f5ed;
        }

        .money.editable {
            cursor: pointer !important;
        }
    }
}

.cellDetails {
    padding: $standard-padding;

    width: 700px;
}

@import "modules/spending/timesheet/components/_common.scss";
@import "./_common";

.header {
    height: 30px;
    padding-left: 11px;
    margin-bottom: 16px;
    border-top-left-radius: $table-header-radius;
    border-top-right-radius: $table-header-radius;
    position: relative;

    :global {
        .schedule-user-name,
        .schedule-cell {
            border: none !important;
            color: $standard-planr-darkblue;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 13px;
            height: 100%;
            font-size: $font-size-standard;
        }

        .schedule-cell {
            justify-content: center;
        }

        .schedule-user-name {
            padding-left: $left-cell-padding;
        }
    }
}

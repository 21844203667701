$planr-font-family: "GothamPro", Arial, sans-serif;
$planr-font-size-extra: 24px;
$planr-font-size-large: 16px;
$planr-font-size-standard: 14px;
$planr-font-size-small: 12px;
$planr-font-size-tiny: 11px;

$planr-font-color: #00273d;
$planr-readonly-input-text: #99a9b1;
$planr-font-color-light: rgba($planr-font-color, 0.4);

$planr-font-sizes: (
    standard: $planr-font-size-standard,
    small: $planr-font-size-small,
    tiny: $planr-font-size-tiny,
    large: $planr-font-size-large,
    extra: $planr-font-size-extra,
);

@mixin planr-font($type: "standard", $imp: false) {
    font-family: $planr-font-family;
    @if $imp == true {
        font-size: map-get($planr-font-sizes, $type) !important;
    } @else {
        font-size: map-get($planr-font-sizes, $type);
    }
    font-style: normal;
    letter-spacing: 0px;
}

@mixin planr-uppercase-font($type: "standard") {
    @include planr-font($type);
    text-transform: uppercase;
}

.bp3-popover-content,
.bp3-dialog {
    @include planr-font();
}

@import "modules/common/components/variables.scss";

.survey {
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    background-color: $standard-planr-gray-color
    :global {
        .block-header {
            padding-left: 10px;
            padding-right: 10px;
        }

        .bp3-icon-cross {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}

.surveyEnded {
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    background-color: #e4e8eb;
    :global {
        .block-header {
            padding-left: 10px;
            padding-right: 10px;
        }

        .bp3-icon-cross {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}

.header {
    background-color: transparent;
    display: flex;
    border: transparent;
    font-size: 11px;
    color: rgba(0, 39, 61, 0.4);
    .status {
        text-transform: uppercase;
        margin-left: auto;
        font-weight: 700;
        &.inactive {
            color: rgba(0, 39, 61, 0.4);
        }

        &.active {
            color: #1dd278;
        }
    }
}
.surveyTop {
    display: flex;
    padding-left: 16px;
    padding-bottom: 8px;
    color: $standard-planr-darkblue;
    position: relative;
    font-weight: 400;
    .surveyEndIcon {
        position: absolute;
        top: 0px;
        right: 16px;
    }
    .surveyName {
        color: $standard-planr-darkblue;
    }
}
.body {
    border-radius: 4px;
    background-color: #fff;
    min-height: 160px;
    padding: 10px;
    flex: 1 1;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
    color: $standard-planr-darkblue;
    font-weight: 300;
    font-size: 14px;
    width: 236px;
    margin: 0px 16px 8px 16px;
}

.bodyEnded {
    border-radius: 4px;
    background-color: #e4e8eb;
    min-height: 160px;
    padding: 10px;
    flex: 1 1;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
    color: $standard-planr-darkblue;
    font-weight: 300;
    font-size: 14px;
    width: 236px;
    margin: 0px 16px 8px 16px;
}

.footer {
    color: rgba(0, 39, 61, 0.5);
    font-size: 11px;
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;

    .peopleCount {
        margin-left: 5px;
    }
}

.result {
    font-size: $font-size-small;

    .stripe {
        margin-top: 7px;
    }

    .progress {
        position: relative;

        .percents {
            position: absolute;
            top: -$font-size-standard;
        }
    }

    :global {
        .bp3-progress-bar .bp3-progress-meter {
            background-color: #ea561e;
        }
    }
}

.form {
    label {
        font-size: $font-size-small !important;
    }

    .variant:first-of-type {
        margin-top: 7px;
    }
}

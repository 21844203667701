@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/planr-scrolls.scss";

$scroll: 7px;
$padding: 15px;

.timeline {
    width: 100%;
    height: 330px;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: $padding 30px $padding - $scroll 25px;
    @include planr-scoll($scroll);
    overflow-x: auto;
    background-color: white;

    .axis {
        border-bottom: 1px dashed $planr-brand-color;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        width: 99%;
    }

    .event {
        min-width: 60px;
        max-width: 60px;
        display: flex;
        height: 100%;
        position: relative;

        &.odd {
            .date {
                top: calc(50% - 80px + #{$scroll});
            }

            .text {
                top: 0;
            }

            .line {
                top: 0;
                bottom: 50%;
            }
        }

        &.isPlanEvent {
            .point {
                background-color: white;
            }
        }

        &:not(.odd) {
            .date {
                bottom: calc(50% - 80px + #{$scroll});
            }

            .text {
                bottom: 0;
            }

            .line {
                top: 50%;
                bottom: 0;
            }
        }

        &:hover {
            cursor: default;

            .text {
                border-color: $planr-brand-color;
            }

            .point {
                width: 14px;
                height: 14px;
                left: calc(50% - 7px);
                top: calc(50% - 7px);
            }
        }

        &.today {
            .text,
            .line {
                display: none;
            }

            .point {
                border-radius: 0;
                transform: rotate(45deg);
                width: 14px;
                height: 14px;
                left: calc(50% - 7px);
                top: calc(50% - 7px);
            }
        }
    }

    .point {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $planr-brand-color;
        position: absolute;
        left: calc(50% - 5px);
        top: calc(50% - 5px);
        border: 2px solid $planr-brand-color;
        z-index: 1;
        transition: 0.2s ease-in;
    }

    .date {
        @include planr-font("tiny");
        position: absolute;
        left: calc(50% - 7px);
        text-align: center;
        text-orientation: mixed;
        writing-mode: vertical-rl;
        background-color: white;
        font-weight: 300;
    }

    .text {
        position: absolute;
        width: 100%;

        width: 180%;
        padding: 2px;
        border-radius: 4px;
        @include planr-font("tiny");
        left: -20px;
        text-align: center;
        background-color: white;
        border: 1px solid $standard-planr-gray-color;
        background-color: $light-planr-gray;
    }

    .line {
        position: absolute;
        border-right: 1px dashed $planr-brand-color;
        width: 1px;
        height: 50%;
        left: calc(50%);
    }
}

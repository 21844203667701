.tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    :global {
        /* can use only UI kit class here because it applies only to active tab */
        .bp3-tab-panel {
            margin-top: 0px;
            flex: 1 1;
            display: flex;
            overflow: hidden;
        }
    }
}

.page {
    background-color: #f7f9fa;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    .right {
        position: absolute;
        align-items: center;
        display: flex;
        right: 40px;
        top: 30px;
    }
}

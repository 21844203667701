@import "./common";

.row {
    transform: scale(0.79);
    transform-origin: left;
    padding-left: $c-padding;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: -30px;
    :global {
        @import "../../../spending/timesheet/components/row";

        .timesheet-user-name {
            display: none;
        }
    }
}

@import 'modules/common/components/variables.scss';

.dndUploader {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px dashed #A4B1BC;
    min-height: 80px;
    align-items: center;
    justify-content: center;
    color: $standard-gray;
    font-size: $font-size-small;
    padding: $standard-padding;

    &.dragging {
        border-color: #00439E;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .link {
        color: #00439E;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    :global {
        .file-uploader {
            line-height: 22px;
        }
    }
}

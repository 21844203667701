@import "modules/root/styles/theme/blueprint/variables.scss";

.usersLabel {
    display: flex;

    > div {
        margin-left: auto;
        color: $blue2;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.usersInput {
    display: flex;

    :global {
        .bp3-icon-delete {
            padding-top: 5px;
            padding-left: 5px;
        }
    }

    svg {
        color: $gray1;
        cursor: pointer;
    }
}

.dialog {
    :global {
        .bp3-inline .bp3-label {
            min-width: 120px;
        }

        .bp3-form-content {
            width: 100%;
        }
    }
}

.department {
    background-color: #ea561e;
    color: white;
}

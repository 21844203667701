@import "modules/common/components/variables.scss";

.form {
    .typeRadio {
        svg {
            cursor: pointer;
            margin-right: $standard-padding;

            path {
                fill: $standard-gray;
            }
        }

        svg.active {
            path {
                fill: $standard-blue;
            }
        }
    }

    .statusInput {
        display: flex;

        :global {
            .bp3-control {
                margin-right: $standard-padding;
            }
        }
    }

    :global {
        .bp3-inline .bp3-label {
            min-width: 120px;
            color: $standard-planr-darkblue;
            font-family: "GothamPro", Arial, sans-serif;
        }

        .bp3-form-content {
            width: 100%;
        }

        .dnd-uploader {
            min-height: 60px;
            margin-bottom: 5px;
        }
    }
}

.fileList {
    overflow: auto;

    label {
        margin-bottom: 5px;
    }
}

@import "modules/common/components/variables.scss";

.spendings {
    .columnsWrapper {
        display: flex;
        flex-direction: row;
    }

    .plan {
        width: 100%;
    }
}

.planTable {
    padding-top: $standard-padding;
    padding-left: $standard-padding;
    display: flex;
    flex-direction: column;
}

.row {
    width: 100%;
    height: $control-height;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;

    .employer,
    .daysCount {
        margin-right: 20px;
    }

    .employer {
        min-width: 360px;
        max-width: 360px;
    }

    .daysCount {
        min-width: 120px;
        max-width: 120px;

        :global {
            .bp3-input-group {
                width: 120px;
            }

            .bp3-form-group {
                margin-bottom: 0;
            }
        }
    }

    .money {
        min-width: 220px;
        max-width: 220px;

        :global {
            .bp3-label {
                display: none;
            }

            .bp3-form-group {
                margin-bottom: 0;
            }
        }
    }

    & + .row {
        margin-top: 8px;
    }
}

.spendingsSpan {
    margin-bottom: 20px;
    margin-left: 22px;
    font-size: 16px;
}

:global {
    .order_own_spending_employer_select {
        width: 360px;
    }
}

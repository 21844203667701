@import "../vars.scss";

.object {
    padding: 2px 0px;
    background-color: $white;
    border-radius: 4px;
    width: 1540px;
    transition: 0.2s ease-in;
    color: $standard-planr-darkblue;
    margin-bottom: 8px;
    .sumNumbersObj {
        margin-bottom: 10px;
    }
    .label {
        display: flex;
        position: relative;
        font-size: $font-size;
        padding-left: $padding;
        font-size: 16px;

        input {
            width: 400px;
        }
    }

    .collapser {
        margin-right: $collapser-padding;
    }

    .outsourcers {
        margin-top: $standard-padding;
    }

    :global {
        .order-payments {
            width: 400px;
        }

        .outsourced-spending-row {
            margin-bottom: $standard-padding;
        }

        .action-icon {
            margin-bottom: 10px;
        }
    }
}

.addButton {
    position: absolute;
    left: 750px;
    top: 10px;
    color: white;
}

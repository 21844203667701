@import "./_icons.scss";

.actions {
    display: flex;
    align-items: center;
    margin-right: $standard-padding;

    @include icons;

    :global {
        .table-add-button-figma {
            margin-bottom: 0 !important;
            margin-right: $standard-padding;
        }

        .action-icon {
            margin-right: $standard-padding;
        }
    }
}

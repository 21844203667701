@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/common/components/variables.scss";

.tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    :global {
        /* can use only UI kit class here because it applies only to active tab */
        .bp3-tab-panel {
            margin-top: 0px;
            padding-left: 16px;
            background-color: #f5f7f8;
            flex: 1 1;
            display: flex;
            overflow: hidden;
            padding-right: 0;
        }

        .bp3-tab[data-tab-id="/expenses"] {
            display: none;
        }
    }
}

@import "modules/common/components/variables.scss";

.tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    :global {
        /* can use only UI kit class here because it applies only to active tab */
        .bp3-tab-panel {
            flex: 1 1;
            display: flex;
            overflow: hidden;

            &.manuals,
            &.standards,
            &.templates,
            &.organisations,
            &.orders {
                overflow: auto;
                padding: 0;
                margin-top: 0px;
            }
        }
    }
}

@import "modules/root/styles/planr/planr-scrolls.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";

.roleDetails {
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    background-color: $planr-white-color;

    .access {
        display: flex;
        flex-direction: row;
        flex: 1 auto;
        overflow: auto;
        overflow-x: hidden;
        flex-wrap: wrap;
        background-color: $planr-white-color;
        margin-top: $standard-padding;
        padding: 0 $standard-padding / 2 $standard-padding / 2 0;

        .column {
            display: flex;
            flex-direction: column;
        }

        .column + .column {
            margin-left: $standard-padding;
        }
    }

    .replaceButton {
        position: absolute;
        top: 0;
        right: 240px;
    }
}

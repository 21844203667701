@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";

$h: 70px;
$rHeight: 36px;
.widget {
    font-family: GothamPro, Arial, Helvetica, sans-serif;
    width: 100%;
    color: $standard-planr-darkblue;
    display: flex;
    flex-direction: column;
    position: relative;
    .headerTableTop {
        background-color: $standard-planr-gray-color;
        font-weight: bold;
        font-size: 14px;
        height: $rHeight;
        line-height: $rHeight;
        border-bottom: $light-border;
        display: flex;
        .greenTotal {
            color: #1dd278;

            background-color: #d8ede6;
            padding: 5px 7px;
            border-radius: 4px;
        }

        .redTotal {
            background-color: #ebdddf;
            color: #d73636;
            padding: 5px 7px;
            border-radius: 4px;
        }
        .name {
            margin-left: 10px;
            flex: 1 auto;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .money {
            width: 100px;
            min-width: 100px;
            padding: 0 5px 0 5px;
        }
    }
    .scroller {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        max-height: 250px;
        background-color: #fff;
    }

    .noData {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 0px;
    }

    :global {
        .block-header {
            padding-left: 10px;
            padding-right: 10px;

            .caret {
                left: auto;
                right: 10px;
            }
        }

        .bp3-collapse-body {
            min-height: $h;
        }
    }
}

.row {
    height: $rHeight;
    line-height: $rHeight;
    border-bottom: $light-border;
    font-size: 12px;
    display: flex;
    font-weight: 400;

    .inventory {
        width: 40px;

        min-width: 40px;
        padding: 0 5px 0 0;
    }

    .name {
        flex: 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .money {
        width: 100px;
        min-width: 100px;
        padding: 0 5px 0 5px;
    }
}

.rowProject {
    background-color: #f3f3f3;
    font-weight: 700;
}

@import "modules/root/styles/planr/animations.scss";
@import "modules/root/styles/planr/variables.scss";

.buttons {
    display: flex;
    &.compact {
        :global {
            .buttons-left {
                padding-right: 5px;
            }
        }
    }

    button[type="submit"].pulse {
        @include shadow-pulsation($planr-green-color);
    }
}

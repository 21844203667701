.bp3-button {
    border-radius: $block-radius;
    font-size: $font-size-small;
    line-height: $control-height;

    &:focus {
        outline: none;
    }

    &:not([class*="bp3-intent-"]) {
        color: $standard-gray;
        background-image: none;
        background-color: white;
        box-shadow: none;
        border: 1px solid $standard-gray;

        &:hover, &.bp3-active, &:active {
            box-shadow: none;
            color: darken($standard-gray, 20%);
        }
    }

    &.bp3-intent-success {
        box-shadow: none;
        border: 1px solid $success-green;
        background-image: none;

        &:hover {
            box-shadow: none;
        }
    }

    &.bp3-minimal {
        border: none;
    }
}

.bp3-button-group.bp3-minimal .bp3-button {
    border: none;
}

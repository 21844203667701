@import "modules/common/components/variables.scss";

$w: 450px;

.assigner {
    width: $w;

    :global {
        .bp3-icon-search,
        .bp3-input-action {
            top: 10px;
        }

        .bp3-input-action {
            right: 10px !important;
        }
    }
}

.popover {
    width: calc(#{$w} - #{$figma-dialog-padding * 2});
}

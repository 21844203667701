.page {
    height: 100%;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;

    .tabs {
        flex: 1 auto;
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;

        :global {
            /* can use only UI kit class here because it applies only to active tab */
            .bp3-tab-panel {
                height: 100%;
                margin-top: 30px;

                overflow: auto;
                // background-color: #fff;

                border-radius: 4px;
            }
        }
    }
}

.refreshbtn {
    margin-left: 30px;
}

.refreshbtn:hover {
    color: rgb(109, 167, 214);
}

@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/common/components/variables.scss";

$height: $pt-grid-size * 2;

.table {
    overflow-y: auto;
    flex: 1 1;
    margin: 16px;
    .toggle {
        line-height: $height * 2;
    }

    :global {
        .ip-address {
            line-height: $font-size;
        }
    }
}

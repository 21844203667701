.rost .month-picker {
    $size: 2rem;

    .rmp-container {
        .rmp-popup {
            transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
            width: $size * 5;

            &.light {
                background-color: $white !important;
            }

            label {
                font-size: $pt-font-size !important;
                line-height: $size !important;
                font-weight: bold;
            }

            .rmp-pad {
                width: 100% !important;

                label {
                    color: $black;
                }

                i {
                    width: $size !important;
                    height: $size !important;
                    line-height: $size !important;
                }

                li {
                    line-height: $size !important;
                    font-size: $pt-font-size !important;
                    -webkit-text-stroke-width: 0 !important;
                    color: $black;

                    &.active,
                    &.active:hover {
                        background-color: $standard-blue;
                    }
                }
            }
        }
    }
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/planr-scrolls.scss";
@import "~@blueprintjs/core/src/components/button/_common.scss";
@import "~@blueprintjs/core/src/components/menu/_common.scss";

$submenu-padding: 5px;
$left-padding: 20px;

.menu {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .divider {
        background-color: rgba(141, 183, 219, 0.4);
    }

    .scroller {
        overflow-y: auto;
        overflow-x: hidden;

        @include planr-scoll();
    }

    :global {
        .bp3-menu:not(.root) {
            padding: 0;
            margin-left: 0;
            transition: 0.2s ease-in;
        }

        .bp3-menu-item:not(.top-level) {
            .bp3-fill {
                font-size: $font-size-small;
            }
        }

        .main-menu-item {
            position: relative;
            z-index: 3;
            width: 303px;

            .top-level .bp3-fill {
                padding: 7px 0px;
            }
        }

        .add-order-button {
            color: $standard-orange;

            text-decoration: none;

            &:hover {
                color: rgba(0, 39, 61, 0.04);
            }
        }

        .toggle-ribbon-button {
            cursor: pointer;
            position: absolute;
            top: 15px;
            right: 45px;
            transition: 0.2s ease-in;
            z-index: 1000;
            &:hover {
                color: #ea561e;
            }
        }
    }
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";

.columns {
    display: flex;
    overflow-y: auto;
    justify-content: space-between;

    .column {
        flex: 1 1;

        padding-left: 3px;
    }
}
:global {
    .bp3-dialog-body {
        // display: flex;

        & > div {
            flex: 1 0;
        }
    }

    .bp3-inline .bp3-label {
        min-width: 120px;
    }

    .bp3-labelsvg {
        margin-top: 5px;
    }

    .bp3-form-content {
        width: 100%;
    }

    .rating-stars {
        line-height: 30px;
    }

    .bp3-form-group {
        margin-bottom: $modal-form-input-padding;
    }
}

.ratings {
    :global {
        .bp3-form-content {
            display: flex;
        }
    }

    .rating {
        margin-right: $standard-padding * 2;
    }
}

.workTypes {
    display: flex;
    margin-left: $standard-padding;
    min-width: 250px;

    :global {
        .bp3-card {
            flex: 1 auto;
            margin-bottom: $modal-form-input-padding;
            padding-bottom: 0;
        }

        label {
            margin: 0;
            width: 100%;

            &:not(.title) {
                display: none;
            }
        }
    }

    .selectRow {
        display: flex;
        margin-bottom: $modal-form-input-padding;

        :global {
            .bp3-popover-wrapper {
                margin-right: 2px;
            }
        }
    }
}

.projects {
    display: flex;
    margin-left: $standard-padding;
    min-width: 560px;

    :global {
        .bp3-card {
            flex: 1 auto;
            margin-bottom: $modal-form-input-padding;
            padding: $standard-padding $standard-padding/2 0 $standard-padding/2;
            overflow-y: auto;
        }

        table {
            width: 100%;

            &:first-child {
                tbody tr:first-child td {
                    box-shadow: none;
                }
            }

            th {
                white-space: nowrap;
            }

            td,
            th {
                font-size: 13px;
            }

            label {
                font-size: $pt-font-size;
            }
        }
    }
}

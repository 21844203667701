@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

.version {
    @include planr-font("tiny");
    font-style: italic;
}

.changelog {
    background-color: white;
    padding: $standard-padding;
    max-height: 500px;
    overflow-y: auto;
    max-width: 400px;

    ol {
        padding-inline-start: $standard-padding*2;
    }
}

.title {
    margin-bottom: 0;
}

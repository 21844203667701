@import 'modules/common/components/variables.scss';

.details {
    :global {
        .bp3-inline .bp3-label {
            min-width: 170px;
        }

        .bp3-form-content {
            width: 100%;
        }

        .bp3-form-group {
            margin-bottom: $modal-form-input-padding;
        }

        .bank-details-table {
            .bp3-html-table {
                td {
                    padding: 1px;
                    box-shadow: none;
                }

                th {
                    padding: 0;
                    text-align: center;
                }
            }
        }
    }
}

@import "modules/common/components/variables.scss";
@import "./_common.scss";

$highlight: 2px solid red;

.wrapper {
    flex: 1 1;
}

.role {
    height: 160px;
    vertical-align: bottom;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    font-size: 12px;
    line-height: 9px;

    .rotated {
        position: absolute;
        top: auto;
        left: 50%;
        transform: translateX(-50%) scale(var(--s, -1));
        bottom: 0;
        writing-mode: vertical-rl;
        text-align: center;
        height: 100%;
        max-width: 100%;
        color: $gray3;
    }
}

.department {
    font-size: 80%;

    div {
        max-height: 30px;
        overflow: hidden;
    }
}

.table {
    border-collapse: collapse;

    td,
    th {
        border: $border;
        font-weight: normal;
    }

    td {
        text-align: center;
        height: $rowHeight;
    }

    tr {
        td:first-child:not(.columnOpener) {
            border-left: 2px solid transparent;
        }
    }

    tr:first-child:not(.current) {
        td {
            border-top: 2px solid transparent;
        }
    }

    .current td,
    .afterCurrent td {
        border-top: $highlight;
    }
}

.body {
    td {
        &.columnSelection {
            border-right: $highlight;
            position: relative;
        }

        &.columnOpener {
            border-left: $highlight;
        }
    }
}

.header {
    th {
        background-color: $white;
    }

    .current {
        background-image: $current;
    }
}

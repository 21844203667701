@import 'modules/common/components/variables.scss';

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: $standard-padding;

    .left {
        justify-content: flex-start;
    }

    button + button {
        margin-left: 10px;
    }

    :global {
        .bp3-icon {
            display: inline-block;
        }

        .bp3-icon + .bp3-icon {
            margin-left: 5px;
        }
    }
}

// colors
$planr-disabled-color: #f7f9fa;
$planr-green-color: #1dd278;
$planr-white-color: #fff;
$planr-brand-color: #ea561e;
$planr-red-color: #e31818;
$planr-brand-color-light: rgba(#ea561e, 0.1);
$planr-brand-color-dark: #b53e11;
$planr-lightblue-color: #36acd7;
$planr-lightblue-color-light: rgba(54, 172, 215, 0.1);
$planr-disabled-btncolor: #e7eaed;
$planr-disabled-input-color: $planr-disabled-color;
$planr-input-color: #edf0f2;
$standard-planr-gray-color: #edf0f2;
$light-planr-gray: #f6f8f9;
$standard-planr-darkblue: #00273d;
$standard-padding: 1rem;
// borders
$planr-border-radius: 4px;

// spaces
$planr-control-space: 8px;

@import "modules/common/components/variables.scss";

.grid {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    flex-direction: column;
    padding-right: $standard-padding;
}

.cellDetails {
    padding: $standard-padding;
    width: 340px;
}

@import "./_common.scss";

.tree {
    min-width: $treeWidth;
    max-width: $treeWidth;

    table {
        border-collapse: collapse;
    }

    td,
    th {
        border: $border;
        white-space: nowrap;
        font-size: 12px;
        color: $standard-planr-darkblue;
    }

    td {
        height: $rowHeight;
    }

    td.level3 {
        cursor: pointer;
        white-space: normal;
        max-width: 122px;
    }

    td.level3Pos {
        cursor: pointer;
        white-space: normal;
        max-width: 75px;
    }

    .current {
        background-image: $current;
    }

    .small {
        font-size: 80%;
        color: $gray3;
    }

    .vertical {
        overflow: hidden;
        @extend .small;

        .text {
            white-space: nowrap;
            transform: rotate(-90deg);
            transform-origin: top left;
            position: relative;
            max-width: $rowHeight;
            top: 3rem;
            line-height: $rowHeight;
            position: relative;
        }
    }
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

.details {
    padding: $standard-padding;
    background-color: $white;
    width: 375px;

    & > div:first-child {
        margin-bottom: $standard-padding;
    }

    .transfer {
        display: flex;
    }

    .transferWarning {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

    :global {
        .days {
            position: absolute;
            top: 2px;
            left: 30px;
            background-color: #f5f7f7;
            z-index: 2;
        }
    }
}

.summary {
    @include planr-font("tiny");
}

@import "modules/common/components/variables.scss";
@import "./common";

.widget {
    width: $c-width + $c-padding * 2;
    padding: $c-padding;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
}

.names {
    display: flex;
    flex-direction: row;

    .dayName {
        text-align: center;
        min-width: $day-width;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        text-transform: uppercase;
    }
}

.wrapper {
    display: flex;
    flex-direction: row;
    flex: 1 auto;
    flex-wrap: wrap;
    border-radius: $radius;
}

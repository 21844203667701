@import "modules/common/components/variables.scss";

.list {
    flex: 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

    .tools {
        display: flex;
    }
    .Mailtable {
        height: 100%;
    }
    .actions {
        display: flex;
        align-items: center;

        margin-right: 6px;
    }

    .addButton {
        position: absolute;
        box-shadow: none !important;
        border-radius: 0;
        width: 49px;
        z-index: 1;
    }

    :global {
        .ag-grid-wrapper {
            flex: 1 auto;
            margin: $standard-padding 0 $standard-padding 0;

            circle {
                opacity: 1;
            }
        }

        .ag-cell-value {
            font-size: 12px;
        }

        .ag-group-expanded,
        .ag-group-contracted {
            display: none !important;
        }

        .pager {
            margin-left: auto;

            :global {
                .bp3-button {
                    color: $standard-planr-darkblue;
                    border: 1px solid $standard-planr-gray-color;
                }

                .bp3-button:first-of-type {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                .bp3-button:last-of-type {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }

                .bp3-intent-primary {
                    background-color: #1dd278;
                    color: #fff;
                }
            }
        }

        .ag-theme-balham .ag-row-odd {
            background-color: $white;
        }

        .ag-theme-balham .ag-row-hover {
            background-color: $light-gray;
        }

        .ag-theme-balham .ag-row-selected {
            background-color: $light-gray;
        }
    }
}

$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome.scss";

.rost {
    .figma-icon {
        &.disabled {
            cursor: not-allowed;
        }

        path {
            fill: $standard-gray !important;
        }

        &:not(.disabled):hover {
            cursor: pointer;

            path {
                fill: $standard-blue !important;
            }
        }
    }

    .white-icon {
        path {
            fill: white !important;
        }
    }

    .blue-icon {
        path {
            fill: $standard-blue !important;
        }
    }

    .red-icon {
        path {
            fill: $error-red !important;
        }
    }

    .green-icon {
        path {
            fill: $success-green !important;
        }
    }

    .warning-icon {
        path {
            fill: $standard-orange !important;
        }
    }

    .action-icon {
        cursor: pointer !important;
    }
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
@import "./_common.scss";

.page {
    flex-direction: column;
    padding: 0 $standard-padding 0 0;
    position: relative;
    font-size: 12px;

    .tabs {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .nestedTabs {
        margin-top: 5px !important;
        display: flex;
        flex: 1 auto;
        overflow: hidden;
        flex-direction: column;
        position: relative;

        :global {
            .bp3-tab {
                margin-left: 0 !important;
                margin-right: 10px !important;
            }

            .bp3-tab-list {
                margin-bottom: $standard-padding;
            }
        }
    }

    :global {
        .functionality-tree {
            border-top: 1px solid transparent;
            background-color: $white;
            position: sticky;
            left: 0;
        }

        .bp3-tab-panel {
            display: flex;
            flex-direction: column;
            overflow: auto;
            flex: 1 1;
            margin-top: 0;
            padding: 0;
            background-color: $light-planr-gray;
        }
    }
}

.header {
    padding-left: $treeWidth + 1px;
    position: sticky;
    top: 0;
    background-color: $light-planr-gray;
    z-index: 1;
}

.body {
    display: flex;
    position: relative;
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

.paymentLinker {
    display: flex;
    flex-direction: row;
    padding: $standard-padding;

    & > div {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 0 $standard-padding $standard-padding;
        background-color: $light-planr-gray;
        border-radius: $planr-border-radius;

        & + div {
            margin-left: $standard-padding/2;
        }
    }
}

.dropHere {
    background-color: white;
    min-height: 30px;
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px;

    &.isDraggingOver {
        border-color: $planr-brand-color;
    }

    .pointer {
        text-align: center;
        padding: $standard-padding;
        font-weight: 300;
        margin-top: auto;
    }
}

.unlink {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    margin-left: auto;

    :global {
        .planr-icon {
            cursor: pointer;
            color: red;
        }
    }
}

@import 'modules/root/styles/theme/blueprint/variables.scss';

.list {
    width: 900px;

    :global {
        .bp3-dialog-body {
            display: flex;
            margin-top: 0;
        }

        .ag-theme-balham {
            height: 400px;
            max-height: 400px;
            display: flex;
            flex: 1 1;
            overflow: hidden;
            flex-direction: column;
        }

        .ag-theme-balham + .ag-theme-balham {
            margin-left: 5px;
        }

        .ag-floating-bottom .bp3-icon {
            display: none;
        }
    }
}

@import "modules/root/styles/planr/variables.scss";

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex: 1 1;
    align-items: center;
}

.page {
    height: 100%;

    background-color: #f7f9fa;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 16px;
    .topSpan {
        margin-bottom: 24px;
        font-size: 18px;
        color: $standard-planr-darkblue;
        text-align: center;
    }
    .right {
        position: absolute;
        align-items: center;
        display: flex;
        right: 40px;
        top: 70px;
    }
}

@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/common/components/variables.scss";

.tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    :global {
        /* can use only UI kit class here because it applies only to active tab */
        .bp3-tab-panel {
            margin-top: 0px;
            padding-left: 16px;
            background-color: #f5f7f8;
            flex: 1 1;
            display: flex;
            overflow: hidden;
            padding-right: 0;
        }
    }
}

.actions {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    right: 16px;
    top: 24px;
}
.right {
    display: flex;
    margin-right: 32px;
    align-items: center;
}

.table {
    flex: 1 1;
    overflow-y: auto;
    position: relative;
    padding-right: $standard-padding;

    :global {
        .ag-layout-auto-height .ag-center-cols-clipper {
            min-height: 0;
        }

        .money {
            position: absolute;
            left: 620px;
        }

        .order-flag {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 3px;
            border-radius: 2px;
            opacity: 0.6;

            &:not(.empty):not(.batch) {
                border: 1px solid black;
            }

            &.batch {
                border: 3px solid green;
            }
        }

        .sorting {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .tools {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
            margin-bottom: 20px;
            height: 52px;
            width: 120px;
            border-radius: 120px;
            background-color: $standard-planr-gray-color;
        }

        .grouping {
            margin-left: 5px;
        }

        .search {
            max-width: 265px;
        }

        .figma-icon {
            margin-right: $standard-padding;
        }

        svg {
            max-height: 20px;
        }
    }
}

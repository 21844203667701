@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";

@import "./main-menu.scss";
@import "./tabs-navigation.scss";
@import "./inputs.scss";
@import "./scroll-bars.scss";
@import "./headers.scss";
@import "./month-picker.scss";
@import "./tables.scss";
@import "./buttons.scss";
@import "./files.scss";
@import "../planr/fonts.scss";

.money {
    font-family: "GothamPro", Arial, sans-serif;
    font-size: 11.5px;
}

.orange {
    color: $standard-orange;
}

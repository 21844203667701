@import "modules/common/components/variables.scss";

.logo {
    // background-image: url('/mount/logo-vector.svg');
    background-image: url("/images/logo.jpg");
    background-repeat: no-repeat;
    min-height: 36px;
    width: 109px;
    margin: 12px 16px;
    display: flex;
    align-items: center;

    .warning {
        position: relative;
        margin-left: 200px;
        -webkit-animation: warning 2s linear infinite;
        animation: warning 2s linear infinite;
        // margin-top: 10px;
    }
}

@-webkit-keyframes warning {
    0% {
        color: #e31818;
    }
    50% {
        color: rgba(34, 34, 34, 0);
    }
    100% {
        color: #e31818;
    }
}
@keyframes warning {
    0% {
        color: #e31818;
    }
    50% {
        color: rgba(34, 34, 34, 0);
    }
    100% {
        color: #e31818;
    }
}

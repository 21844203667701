@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

.consignmentRow {
    box-shadow: 0px 2px 4px 0px rgba(34, 60, 80, 0.2);
    padding: 5px;
    margin-bottom: $standard-padding;

    &:hover {
        border-color: darken($standard-planr-gray-color, 50%);
    }

    :global {
        .bp3-inline .bp3-label {
            min-width: 50px;
            width: 50px;
        }

        .bp3-form-group {
            margin-bottom: 5px;
        }

        .planr-button:not(.input-crossbtn),
        .bp3-input {
            @include planr-font("small", true);
            height: auto;
            line-height: 14px;
            padding: 5px 7px;
        }
    }
}

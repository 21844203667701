@import "modules/common/components/variables.scss";

.page {
    flex-direction: column;
    padding: 0;
    position: relative;
    overflow: hidden;

    .addButton {
        position: absolute;
        margin-right: $standard-padding;
        top: 24px;
    }

    .SumValue {
        display: flex;
        position: absolute;
        margin-right: $standard-padding;
        top: 144px;
        width: 500px;
    }
}
.tools {
    position: absolute;
    top: 24px;
    right: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    :global {
        .figma-icon {
            margin-right: 1rem;
        }
    }
}
.resetPageBtn {
    margin-top: 35px;
    width: 300px;
    margin-left: 100px;
    margin-bottom: 70px;
}
:global {
    .spending-details-form {
        .bp3-inline .bp3-label {
            min-width: 170px;
        }

        .bp3-form-content {
            width: 100%;
        }
    }
    .header-block {
        margin-left: 80px;
    }
}

@import "modules/common/components/variables.scss";

.content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow-y: scroll;
}

.orderTitle {
    position: absolute;
    display: flex;
    width: 100%;
    top: 20px;
    font-size: 32px;
    font-weight: bold;
    padding: 0px 24px;
    // background-color: $light-planr-gray;
    color: $standard-planr-darkblue;
    align-items: center;
}
.page {
    height: 100%;
    background-color: #f7f9fa;
    padding-left: 16px;

    overflow-y: scroll;
}
.contents {
    flex: 1 1;
    overflow-y: auto;
}
.clearInfo {
    color: $standard-planr-darkblue;
}
.plus {
    margin-left: $standard-padding;
}

@import "modules/common/components/variables.scss";

.tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    :global {
        /* can use only UI kit class here because it applies only to active tab */
        .bp3-tab-panel {
            flex: 1 1;
            display: flex;
            overflow: hidden;
            background-color: #f7f9fa;
            margin-top: 0px;
        }
    }
}

.page {
    display: flex;
    flex: 1 auto;
    overflow: auto;
    padding-left: 16px;

    .left {
        display: flex;
        flex-direction: column;
        flex: 1 auto;
        position: relative;
    }

    .right {
        display: flex;
        flex-direction: column;
        width: 600px;
        margin-left: 20px;
        margin-right: 16px;
        margin-top: 24px;
    }

    &.compact {
        overflow: hidden;
    }

    :global {
        .main-widget {
            margin-bottom: $standard-padding;
        }

        .own-spendings-widget {
            max-width: 490px;
        }

        .outsourcer-details {
            display: flex;
            flex: 1 auto;
            overflow: hidden;
            position: relative;

            form {
                display: flex;
                flex-direction: column;
                flex: 1 auto;
                overflow: hidden;
                margin-bottom: 10px;
            }

            .bp3-dialog-body {
                overflow: auto;
                padding-right: 10px;
            }

            .bp3-dialog-footer {
                padding-right: 10px;
            }

            .outsourcer-details-projects {
                background-color: transparent;
            }
        }
    }
}

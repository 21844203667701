@import "./_common.scss";

.block {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    align-self: flex-start;

    &:first-child {
        margin-top: -10px;
    }
    .departmentName {
        display: flex;
        position: relative;
        width: 285px;
        padding-right: 40px;

        .department {
            max-width: calc(#{$name} - 5rem);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 285px;
            font-size: 12px;
        }

        :global {
            .peoples {
                position: absolute;
                left: 245px;
                min-width: 40px;
            }
        }
    }
    :global {
        .peoples {
            svg {
                path {
                    fill: $white;
                }
            }
        }

        @import "./row";
    }
}

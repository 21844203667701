$font: GothamPro, -apple-system, BlinkMacSystemFont, Arial, sans-serif;
$font-size: 14px;
$font-size-standard: 14px;
$font-size-large: 16px;
$font-size-extra: 20px;
$font-size-small: 12px;
$scroll-bar: 7px;
$standard-padding: 1rem;
$black: #000000;
$white: #ffffff;
$standard-radius: 4px;
$modal-form-input-padding: 8px;

$standard-gray: #a4b1bc;
$light-gray: #f3f5f7;
$standard-blue: #1b3c58;
$standard-planr-gray-color: #edf0f2;
$standard-planr-darkblue: #00273d;

$standard-orange: rgb(239, 127, 57);
$success-green: #009329;
$neutral-gray: #8d8d8d;
$error-red: #e80000;
$label-color: #4e4e4e;
$selected-row-color: #b7e4ff;
$total-color: #ebfff0;

$table-header-radius: 8px;
$block-radius: 8px;

$blockHeaderHeight: 28px;
$standard-border-width: 1px;
$standard-border: $standard-border-width solid rgba(27, 60, 88, 0.4);
$light-border: $standard-border-width solid rgba(164, 177, 188, 0.4);

$control-height: 26px;
$control-border: $standard-border;

$figma-dialog-padding: 30px;

$table-row-height: 20px;

$figma-table-icon-size: 12px;

@mixin figma-input {
    border: 1px solid rgba(27, 60, 88, 0.4);
    &:not(:focus) {
        box-shadow: none;
    }
}

@mixin buttons-width {
    .bp3-button {
        min-width: 100px;
    }
}

.bp3-input {
    box-shadow: none;
    border: $control-border;

    &:disabled,
    &.bp3-disabled {
        border-color: transparent;
    }

    &:focus,
    &.bp3-active {
        box-shadow: none;
        border-color: $black;
    }
}

.bp3-input-group .bp3-input-action {
    height: $pt-input-height;
    line-height: $pt-input-height;
}

.bp3-form-group.bp3-intent-danger {
    .bp3-input {
        &:not(:disabled),
        &:not(.bp3-disabled) {
            border-color: $error-red;
        }
    }
}

.bp3-label {
    color: $label-color;
    font-size: 12px;
    color: $standard-planr-darkblue;
}

.bp3-text-muted {
    position: relative;
}

/* focus fix */
.bp3-switch,
.bp3-checkbox {
    input:focus {
        outline: none;

        & ~ .bp3-control-indicator {
            outline: none;
        }
    }
}

textarea {
    resize: vertical;
}

/* select */
.select .bp3-popover-target {
    display: block;
    width: 100%;

    & > div {
        width: 100%;
    }

    .bp3-button.selected-option {
        border-radius: $pt-border-radius;
        border: $control-border;
        padding-right: 0;
        color: $black;
        font-size: $font-size;
        display: flex;
        height: $control-height;

        .bp3-button-text {
            flex: 1 auto;
            white-space: nowrap;
            overflow: hidden;
        }

        &.bp3-disabled,
        &:disabled {
            color: $button-color-disabled;
            background-color: $white;
        }
    }

    .bp3-icon-caret-down,
    .bp3-icon-delete {
        width: 22px;
        height: $pt-input-height - $standard-border-width * 2;
        display: flex;
        align-items: center;
        border-left: $control-border;
        justify-content: center;
        color: $black;
    }
}

.bp3-select-popover {
    .bp3-popover-arrow {
        display: none;
    }

    .bp3-popover-content {
        padding-left: 0; // $pt-grid-size / 2
        padding-right: 0;
        width: 100%;
        display: block;

        .bp3-input-group {
            margin-left: $pt-grid-size/2;
            margin-right: $pt-grid-size/2;
            margin-bottom: $pt-grid-size/2;

            input {
                width: 98%;
                margin-top: 5px;
                border: $standard-border-width solid rgba(164, 177, 188, 0.4);
            }
        }
    }

    .bp3-menu,
    .bp3-menu-item {
        border-radius: 0;
    }

    .bp3-menu-item.bp3-intent-primary.bp3-active {
        background-color: $menu-item-color-hover;
        color: $black;
    }
}
// #bp3-tab-panel_MainTabsPage_\#tab\=table
//     > div
//     > div.Table_filters__CVxmg
//     > div.Table_filterSelect__kdD_P
//     > span
//     > span
//     > div
//     > div,
// body
//     > div.bp3-portal
//     > div
//     > div.bp3-dialog-container.bp3-overlay-content.bp3-overlay-appear-done.bp3-overlay-enter-done
//     > div
//     > form
//     > div.bp3-dialog-body.EventEditor_dialog__2tWNL
//     > div:nth-child(6)
//     > div
//     > div
//     > span
//     > span
//     > div
//     > div
.bp3-tag-input {
    background: $standard-planr-gray-color;
    border: 3px solid $standard-planr-gray-color;
    min-height: 38px;
    border-radius: 4px;
    padding: 2px 10px;
    padding-right: 40px;
    cursor: pointer;
    transition: 0.2s ease-in;
    &:hover {
        background: #fff;

        border: 3px solid #fff;
    }

    &:active {
        border: 3px solid #369cd7;
        border-radius: 4px;
    }
}

.bp3-input.bp3-active {
    box-shadow: none;
    border-color: #369cd7;
    border-radius: 4px;
}
.bp3-tag-input .bp3-tag {
    background-color: #ea561e;
}

/* select end */

/** borderless inputs */

.borderless {
    &.select {
        .bp3-popover-target .bp3-icon-caret-down,
        .bp3-popover-target .bp3-icon-delete {
            border-left-color: transparent;
        }

        .bp3-button {
            border-color: transparent !important;
        }
    }

    &.date-range-input {
        .bp3-input-group .bp3-input {
            border-color: transparent !important;
        }
    }

    &.sum-range-input {
        .bp3-input-group .bp3-input {
            border-color: transparent !important;
        }
    }
}
body > div.bp3-portal > div > div > div > div > div > ul {
    min-width: 400px;
}
/** in table */
.rost .ag-theme-balham.designed {
    .bp3-input-group .bp3-input-action {
        height: 20px;
        line-height: 20px;
    }
}

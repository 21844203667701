@import "./_common.scss";

.row {
    display: flex;
    flex-direction: row;
    color: $standard-planr-darkblue;

    :global {
        .timesheet-user-name {
            background-color: #fff;
            padding-left: 10px;
            height: 46px;
            border-bottom: $border;
            border-right: $border;
        }
    }
}

.timesheet-user-days {
    position: relative;
}
.nonProduction {
    background: #e7eaed;
    color: #8b9ca7;
}

.page {
    padding: 0;
    display: flex;
    flex: 1 1;
    overflow: hidden;

    :global {
        .full-size {
            flex: 1 1;
        }
    }
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/tags.scss";

.page {
    padding: $standard-padding $standard-padding $standard-padding 2px;
    position: relative;
    overflow: auto;
    margin: 16px;
    margin-top: 0px;

    .column + .column {
        margin-left: 12px;
    }

    .column {
        display: flex;
        flex-direction: column;
        position: relative;
        color: $standard-planr-darkblue;

        .row {
            display: flex;
            flex: 1 1;
            flex-direction: row;
            margin-bottom: 10px;

            .chartBlock {
                background-color: $standard-planr-gray-color;
                padding: 12px;
                padding-right: 7px;
                min-height: 500px;
                border-radius: 4px;
                margin-right: 6px;
            }

            .mainChartBlock {
                background-color: $standard-planr-gray-color;
                padding: 12px;
                padding-right: 7px;
                width: 100%;
                height: 100%;
                border-radius: 4px;
                margin-right: 6px;
            }

            .chartRow {
                margin-top: -9px;
            }
            .chartRow:nth-child(2) {
                margin-left: 18px;
            }

            .chartBlockCollapse {
                width: 409px;
                padding: 0px;
            }
        }
    }

    .overheadSummary {
        position: absolute;
        right: 12px;
        @include planr-font("tiny");
        @include planr-tag-color();
        @include planr-tag-padding();
        font-weight: 700;
        line-height: $planr-font-size-small;
        border-radius: $planr-border-radius;
        text-align: right;

        .active {
            color: #738694;
        }
    }

    :global {
        .chart-period-switcher {
            margin-left: auto;
        }

        .collapse-expand-all {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 2;
        }

        .collapse-caret {
            left: 12px;
            top: 13px;
            right: auto;
        }

        .planr-tag {
            position: absolute;
            right: 12px;
            top: 13px;
        }

        .fakeCollapse {
            position: absolute !important;
            top: -9999px !important;
            left: -9999px !important;
        }
    }
}

.widget {
    width: auto;
    position: relative;
    background-color: #fcfcfc;
    border-radius: $block-radius;
    margin-right: 5px;

    &.collapsable {
        min-height: 40px;

        .collapser {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 10px;
            z-index: 1;
            line-height: 40px;
            width: 100%;
            background-color: #fcfcfc;
        }
    }
}

.block {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 5px;
    min-height: 110px;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;

    .header {
        margin: 0;
        color: #e31818;
        font-size: 13px;
        font-weight: 400;
    }

    .column {
        display: flex;
        flex-direction: column;
        font-size: $font-size-small;
        width: 100%;

        &.righted {
            div {
                text-align: right;
                justify-content: flex-end;
            }
        }

        div,
        strong,
        h2 {
            line-height: 25px;
            width: 100%;
            display: flex;
            align-items: center;
        }

        :global {
            .bp3-icon {
                margin-left: 5px;
                margin-bottom: 2px;
                cursor: pointer;
            }
        }
    }
}

.layout {
    flex: 1 1;
    display: grid;
    grid-template-rows: repeat(6, 134px);
    grid-template-columns: repeat(9, 180px);
    min-height: 6 * 134px;
    min-width: 9 * 180px;

    > div {
        border: 1px solid greenyellow;
    }
}

.refreshbtn {
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(204, 204, 204);
    border-radius: 5px;
    margin-bottom: 30px;
    transition: 0.2s ease-in;
}

.refreshbtn:hover {
    cursor: pointer;
    border: 2px solid rgb(153, 153, 153);
    color: rgb(37, 46, 114);
}

.refreshbtn:active {
    cursor: pointer;
    border: 2px solid rgb(95, 95, 95);
    color: rgb(0, 0, 3);
}

:global {
    .planr-chart-tooltip {
        text-align: center;

        strong {
            display: block;
            flex-direction: row;
            align-items: center;
            font-size: 10px;
            line-height: 10px;
        }

        .planr-chart-tooltip_bullet {
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            line-height: 10px;
            font-size: 10px;
            margin-right: 3px;
        }
    }
}

.credit {
    color: $planr-brand-color;
}

.creditTags {
    margin-bottom: 10px;
    text-transform: uppercase;

    font-size: 12px;
    font-weight: bold;
    .creditTagsLabel {
        float: left;
    }
    .creditTagsValue {
        display: block;
        float: right;
        padding-right: 20px;
    }
}
.columnValue {
    text-align: right;
    width: 40%;
    float: right;

    .columnInput {
        width: 70%;
        float: left;
    }
    .columnBtns {
        width: 30%;
        float: left;
    }
}
.columnRow {
    line-height: 24px;
    vertical-align: middle;
}
.columnName {
    width: 60%;
    float: left;
}

@import 'modules/common/components/variables.scss';

.details {
    .iconUploader {
        :global {
            .bp3-input-group {
                display: flex;
                flex-direction: row;
            }

            .file-uploader {
                margin: 0;
                float: none;
            }

            img {
                align-self: center;
                margin-right: $standard-padding;
            }
        }
    }

    :global {
        .bp3-inline .bp3-label {
            min-width: 170px;
        }

        .bp3-form-content {
            width: 100%;
        }

        .bp3-form-group {
            margin-bottom: $modal-form-input-padding;
        }
    }
}

@import "./_common.scss";

.row {
    border: 0;
    display: flex;
    width: auto;
    font-size: 0;
    margin-top: 30px;

    :global {
        .timesheet-user-name {
            color: rgba(0, 39, 61, 0.5);
        }
    }
}

.part {
    display: inline-block;
    color: rgba(0, 39, 61, 0.5);
    font-size: $font-size * 0.95;
    text-align: center;
}

.baseCell {
    margin-top: 5px;
    height: $cell;
    padding-top: 5px;
    border-top: 3px solid transparent;
    border-right: 1px solid transparent;
}

.cell {
    @extend .baseCell;

    &:last-child {
        border-right: 0;
    }

    &.weekend {
        background-color: transparent;
    }

    &.preWeekend {
        border-right-color: transparent;
    }

    &.preToday {
        border-right-color: transparent;
    }

    &.today {
        color: #369cd7;
        border-left: $today-border;
        border-right: $today-border;
        border-top: $today-border;
        border-top-left-radius: $today-border-radius;
        border-top-right-radius: $today-border-radius;
    }
}

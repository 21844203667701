@import "modules/common/components/variables.scss";

.widget {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f9f9fa;

    .container {
        display: flex;
        flex-direction: row;
        flex: 1 auto;
        position: relative;
    }

    .nav {
        box-sizing: border-box;
        display: flex;
        position: absolute;
        flex-direction: row;
        width: 70px;
        align-items: center;
        justify-content: space-around;
        top: 247px;
        right: 20px;
        z-index: 10;
    }

    .noData {
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
    }

    :global {
        .survey {
            margin-top: -$blockHeaderHeight;
            max-width: 50%;
        }

        .survey:first-of-type {
            z-index: 1;
        }

        .block-header.widget-header {
            padding-left: 10px;
            padding-right: 10px;

            .action-icon {
                margin-left: auto;
            }
        }
    }
}

.addButton {
    margin-left: $standard-padding;
}

@import "modules/root/styles/planr/planr-scrolls.scss";

.employee {
    overflow-y: auto;

    padding: 12px;
    width: 396px;
    // @include planr-scoll();
}

.stripe {
    cursor: pointer;

    + .stripe {
        margin-top: 8px;
    }
}

.name {
    width: 100%;
    height: 12px;
    .nameValue {
        width: 85%;
        float: left;
    }
    .value {
        font-weight: bold;
        width: 15%;
        float: left;
        text-align: right;
        font-size: 12px;
    }
}

.percents {
    margin-top: 6px;
    width: 100%;
    background-color: rgba(0, 39, 61, 0.05);

    > div {
        background-color: $standard-planr-darkblue;
        height: 4px;
    }
}

.dialog {
    .variants {
        :global {
            .bp3-input-group {
                margin-bottom: 5px;
            }
        }
    }

    .variant {
        display: flex;
        align-items: center;
    }
}

@import "modules/root/styles/planr/variables.scss";

.container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}
.leftBlock {
    transition: 0.3s ease-out;
    width: 33%;
    height: 100%;
    display: flex;
    padding-bottom: 24px;
    background-color: #f7f9fa;
    align-items: center;
}

.leftBlockHidden {
    transition: 0.3s ease-in;
    width: 0%;
    height: 100%;
    display: flex;
    padding-bottom: 24px;
    background-color: #f7f9fa;
    align-items: center;
}
.rightBlock {
    transition: 0.3s ease-out;
    border-left: 2px solid $standard-planr-gray-color;
    width: 67%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f7f9fa;
    padding-bottom: 24px;
    padding-top: 24px;
    align-items: center;
    position: relative;

    .filterBtns {
        position: absolute;
        right: 24px;
        top: 16px;
        display: flex;
    }
}

.rightBlockFull {
    transition: 0.3s ease-in;
    border-left: 2px solid $standard-planr-gray-color;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f7f9fa;
    padding-bottom: 24px;
    padding-top: 24px;
    align-items: center;
    position: relative;

    .filterBtns {
        position: absolute;
        right: 24px;
        top: 16px;
        display: flex;
    }
}
.page {
    height: 100%;

    background-color: #f7f9fa;
    padding-left: 16px;
    padding-right: 0px;
    padding-top: 24px;

    .right {
        position: absolute;
        align-items: center;
        display: flex;
        right: 40px;
        top: 70px;
    }
}
.topSpan {
    margin-bottom: 24px;
    font-size: 18px;
    color: $standard-planr-darkblue;
    text-align: center;
}

.nav {
    display: flex;
    flex-direction: row;
}

@import "modules/common/components/variables.scss";

.columns {
    flex: 1 1;
    margin-top: 20px;
    flex-direction: row;
    display: flex;
    overflow-y: auto;

    .column {
        position: relative;
        padding-left: 3px; // inputs outline
        width: 400px;

        & + .column {
            margin-left: $standard-padding * 5;
        }
    }
}

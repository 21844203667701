@import "modules/root/styles/planr/variables.scss";

.container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    margin-top: 60px;
}
.page {
    height: 100%;
    background-color: #f7f9fa;
    padding-left: 16px;
    padding-right: 16px;

    padding-top: 24px;
    .topSpan {
        margin-bottom: 24px;
        font-size: 18px;
        color: $standard-planr-darkblue;
        text-align: center;
        font-family: GothamPro, Arial, Helvetica, sans-serif;
    }
    .right {
        position: absolute;
        align-items: center;
        display: flex;
        right: 40px;
        top: 70px;
    }
}

.nav {
    display: flex;
    flex-direction: row;
}

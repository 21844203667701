@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/root/styles/planr/variables.scss";

.rowEdit {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    min-height: $control-height;
    padding: 5px $standard-padding/2 5px $standard-padding/2;
    border-bottom: $light-border;
    align-items: baseline;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
    transform: scale(1.01);

    & > div {
        display: flex;
        align-items: center;
    }

    &:not(.headerRow):first-of-type {
        border-top: $light-border;
    }

    .iconCell {
        width: 40px;
        justify-content: center;
        align-self: center;
    }

    .status {
        width: 80px;
        justify-content: center;
    }
    .stage {
        width: 200px;
        white-space: normal;
        text-overflow: ellipsis;
        padding-right: $standard-padding/2;
    }

    .version {
        width: 30px;
        padding-right: $standard-padding/2;
    }
    .name,
    .description {
        white-space: normal;
        text-overflow: ellipsis;
        overflow-x: scroll;
        display: flex;
        flex: 1 1;
        padding-right: $standard-padding/2;
    }

    .file {
        width: calc(150px + #{$standard-padding/2});
        padding-right: $standard-padding/2;
        display: flex;
        flex-direction: column;
    }

    &.headerRow {
        background-color: $light-planr-gray;
        .file {
            justify-content: center;
        }
    }

    :global {
        .bp3-input-group {
            width: 100%;
        }

        input {
            padding: 0 5px !important;
        }

        .hidden {
            display: none;
        }
    }
}

.row {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    min-height: $control-height;
    padding: 5px $standard-padding/2 5px $standard-padding/2;
    border-bottom: $light-border;
    align-items: center;
    width: 100%;
    background-color: #fff;
    color: $standard-planr-darkblue;
    font-size: 12px;

    & > div {
        display: flex;
        align-items: center;
    }

    &:not(.headerRow):first-of-type {
        border-top: $light-border;
    }

    .iconCell {
        width: 40px;
        justify-content: center;
        align-self: center;
    }

    .status {
        width: 80px;
        justify-content: center;
        display: flex;
        align-items: center;

        .statusLoad {
            font-size: 10px;
            color: #1dd278;
            background-color: rgba(29, 210, 120, 0.1);
            border-radius: 4px;
            padding: 5px 7px;
        }

        .statusInquired {
            font-size: 10px;
            color: #d76c36;
            background-color: rgba(215, 108, 54, 0.1);
            border-radius: 4px;
            padding: 5px 7px;
        }

        .statusNone {
            font-size: 10px;
            color: #e31818;
            background-color: rgba(215, 54, 54, 0.1);
            border-radius: 4px;
            padding: 5px 7px;
            width: 71px;
            align-items: center;
            text-align: center;
        }
    }
    .stage {
        width: 200px;
        white-space: normal;
        text-overflow: ellipsis;
        padding-right: $standard-padding/2;
    }

    .version {
        width: 30px;
        padding-right: $standard-padding/2;
    }
    .number {
        width: 300px;
        display: flex;
        overflow-x: scroll;
    }

    .mentor {
        width: 90px;
        justify-content: center;
        display: flex;
        overflow-x: scroll;

        :global {
            .bp3-checkbox {
                margin: 5px 0 0 0;
            }
        }
    }

    .name,
    .description {
        white-space: normal;
        text-overflow: ellipsis;
        overflow-x: scroll;
        display: flex;
        flex: 1 1;
        padding-right: $standard-padding/2;
    }

    .file {
        width: calc(150px + #{$standard-padding/2});
        padding-right: $standard-padding/2;
    }

    &.headerRow {
        background-color: $light-planr-gray;
        font-size: 14px;
        .file {
            justify-content: center;
        }
    }

    :global {
        .bp3-input-group {
            width: 100%;
        }

        input {
            padding: 0 5px !important;
        }

        .hidden {
            display: none;
        }
    }
}
.headerMg {
    margin-top: 8px;
}

.filesVersion {
    padding: 10px;
    .rowVersion {
        display: flex;
        align-items: center;
        .file {
            width: calc(200px + #{$standard-padding/2});
            padding-right: $standard-padding/2;
            display: flex;
            flex: 1 1;
            flex-direction: column;
        }

        &.headerRow {
            background-color: $light-planr-gray;

            .file {
                justify-content: center;
            }
        }

        .version {
            font-size: 12px;
        }
    }
}

.planr-content-tabs-navigation {
    @include planr-font("standard");
    margin-top: 0px !important;
    margin-bottom: 10px;

    .bp3-tab-indicator-wrapper {
        display: none;
    }

    .bp3-tab {
        border: 2px solid $standard-planr-gray-color !important;
        margin-right: -30px !important;
        margin-left: 40px !important;
        transition: 0.2s ease-in;
        padding: 4px 20px 6px 20px;
        background: $standard-planr-gray-color !important;
        border-radius: 4px;
        text-align: center;
    }
    .bp3-tab:hover {
        color: $planr-lightblue-color;
        background: $planr-lightblue-color-light !important;
        border: 2px solid $planr-lightblue-color-light !important;
    }

    .bp3-tab[aria-selected="true"] {
        position: relative;
        overflow: visible;
        color: #fff;
        background-color: $planr-lightblue-color !important;
        border: 2px solid $planr-lightblue-color !important;
    }

    .bp3-tab-list {
        padding-left: $standard-padding;
        width: 80%;
        overflow-x: auto;

        & > *:not(:last-child) {
            margin-right: 0;
            padding-right: 20px;
            padding-left: 20px;
        }
    }
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";

.page {
    flex: 1 auto;
    display: flex;
    flex-direction: row;
    overflow: auto;
    background-color: $light-planr-gray;
    padding: 24px;

    :global {
        .file-system {
            min-width: 700px;
        }

        .bp3-card {
            padding: 0;
            margin: 5px;
            display: flex;
            flex: 1 auto;
        }
    }
}

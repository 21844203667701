@import "modules/root/styles/planr/variables.scss";

.popup {
    width: 730px;

    :global {
        .bp3-inline .bp3-label {
            width: 400px;
        }

        .bp3-form-content {
            width: 100%;
        }
    }
}

.textInfo {
    color: $standard-planr-darkblue;
}

.imageViewer {
    display: flex;
    flex-direction: column;

    .image {
        flex: 1 1;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #525659;
    }
}

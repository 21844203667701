@import "../vars.scss";

.block {
    padding: 12px 0px;
    background-color: $white;
    border-radius: 4px;
    width: 100%;
    transition: 0.2s ease-in;
    color: $standard-planr-darkblue;
    margin-bottom: 16px;

    .span {
        margin-right: 50px;
        display: flex;
        align-items: center;
    }
    .sumNumbersObj {
        display: flex;
        margin-top: 25px;
        float: right;
        position: relative;
    }
    .label {
        display: flex;
        flex-direction: column;
        position: relative;
        font-size: $font-size;
        padding-left: $padding;
        font-size: 16px;
        cursor: pointer;
        strong {
            display: inline-block;
        }
    }

    .collapser {
        margin-right: $collapser-padding;
    }

    :global {
        .action-icon {
            margin-left: $cross-padding;
        }
    }
}
.block:last-child {
    margin-bottom: 0px;
}

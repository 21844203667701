@import "modules/spending/timesheet/components/_common.scss";
@import "./_common";

$offcet: 80px;

.page {
    position: relative;
    width: 98%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0 $standard-padding 0 $standard-padding;
    background-color: $light-planr-gray;
    :global {
        .bp3-form-group {
            margin-bottom: 0;
        }

        .add-button {
            margin-top: -60px;
            margin-right: 16px;
            z-index: 1;
            border-radius: 120px;
        }

        .buttons {
            margin-bottom: $standard-padding;
        }

        .employer-name {
            padding-left: $left-cell-padding;
            max-width: 100%;
        }

        .user-filter {
            display: inline-block;
            margin-left: $standard-padding;
        }
    }
}

.zoomArea {
    transform-origin: top left;
    align-items: flex-start;
    align-self: flex-start;
    width: 100%;
    overflow-y: scroll;

    :global {
        .department-block:first-of-type .block-header {
            margin-top: 0;
        }

        .schedule-user-row .schedule-user-name {
            background-color: $white;
        }
    }
}

.filters {
    margin-top: $standard-padding;
    // margin-bottom: -30px;
    display: flex;
    flex-direction: column;

    .left,
    .right {
        display: flex;
        margin-right: 16px;
        align-items: center;
    }

    .left,
    .right {
        > svg {
            width: 21px;
            height: 21px;
            margin-right: $standard-padding;
        }
    }

    .left {
        position: relative;
    }

    .center {
        display: flex;
        margin-right: 320px;
        align-items: center;
        justify-content: center;
    }

    .override {
        display: flex;
        position: absolute;
        top: -65px;
    }

    .periodSlider {
        display: flex;
        position: relative;
        justify-content: center;
        margin-bottom: $standard-padding/2;

        .actions {
            position: absolute;
            right: 0;
            display: flex;
            justify-content: center;
            margin-top: 10px;
        }

        :global {
            .zoomer {
                margin-left: $standard-padding;
            }
        }
    }

    .tools {
        display: flex;
        min-height: $control-height;

        .activeIcon {
            path {
                fill: $standard-blue !important;
            }
        }
    }
}

.totalLine {
    margin-bottom: $standard-padding/2;
    background-color: #e1f5ed;
    color: $standard-planr-darkblue;
    border-radius: 4px;

    .totalTitle {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 18px;
        background-color: #e1f5ed;
        width: 291px;
        height: 60px;
        margin-left: -10px;
        position: absolute;

        top: 0px;
        padding: 18px 12px;
        padding-right: 211px;
    }

    .actualTotal {
        font-weight: bold;
        background-color: #cef2e2;
        color: $standard-planr-darkblue;
        :global {
            .company-spending-per-hour {
                visibility: hidden;
            }
        }
    }

    .planTotal {
        :global {
            .company-spending-per-hour {
                height: 100%;
                font-weight: bold;
                color: $standard-planr-darkblue;
                background-color: #cef2e2;
                padding-bottom: 30px;
            }

            .green-cell {
                width: 20px;
                background-color: #cef2e2;
                height: 30px;
            }
        }
    }

    :global {
        .total-line {
            height: 30px;
            position: relative;
        }
    }
}

.nodata {
    margin: auto;
}

.zoomer {
    margin-left: $offcet;
    display: inline-block;
}
.hiddenZoomArea {
    display: none;
}
@media screen and (max-width: 1900px) {
    .filters {
        margin-bottom: -30px;
    }

    .showZoomArea {
        display: none;
    }

    .hiddenZoomArea {
        display: flex;
        flex-direction: column;
    }
}

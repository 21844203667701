@import "modules/root/styles/planr/planr-scrolls.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";

.page {
    display: flex;
    flex-direction: row;
    flex: 1 auto;
    overflow: hidden;
    padding: 0 $standard-padding $standard-padding $standard-padding;

    .departmentName {
        @include planr-font("small");
        margin: 0;
        border-radius: 0;
    }

    :global {
        .role-details {
            padding-top: $standard-padding;
            padding-left: $standard-padding;
        }
    }
}

.tools {
    display: flex;
    position: absolute;
    top: 0;
    right: $standard-padding * 2;
    align-items: center;
    height: calc(40px + #{$standard-padding});
}

.block {
    :global {
        .collapse {
            padding-top: 0;
        }
    }
}

.roleList {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    background-color: $planr-white-color;
    overflow: hidden;
}

.roleName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    padding: 4px 10px 4px 10px;

    &.selected,
    &:hover {
        background-color: #e1e6e9;
    }
}

.positions {
    min-width: 400px;
    width: 400px;
    overflow: auto;
    margin-right: 5px;
    @include planr-scoll();

    :global {
        .planr-block-header,
        .collapse {
            width: 100%;
        }
    }
}

@import "../vars.scss";

.spendings {
    position: relative;
    width: 696px;
    color: $standard-planr-darkblue;

    .label {
        padding-left: $padding;
        font-size: 16px;
        position: relative;

        .departmentHeader {
            width: 300px;
            white-space: nowrap;
            overflow: hidden;
        }

        .labelNumbers {
            display: flex;
            margin-top: 25px;
            position: relative;
        }
    }

    .row:nth-child(even) {
        background-color: #f7f9fa; /* фон нечетных строк */
    }

    .row {
        display: flex;
        flex-direction: row;
        height: 26px;
        align-items: center;
        font-weight: 400;
        margin: 0px 16px;
    }

    .department {
        width: 220px;
        padding-right: $standard-padding;
        padding-left: 6px;
    }

    .info {
        color: #3d3d3d;
        font-size: 13px;
    }
    .money {
        width: 320px;
    }
    .shortMoney {
        color: #afafaf;
    }
    .hours {
        width: 100px;
    }

    .collapser {
        margin-right: 10px;
    }

    :global {
        .bp3-collapse-body {
            margin-top: $standard-padding;
        }
    }
}

.period {
    padding: 12px 10px;
    background-color: $white;
    border-radius: 4px;

    transition: 0.2s ease-in;
    color: $standard-planr-darkblue;
    margin-bottom: 8px;
    width: 693px;
}

@import "modules/common/components/variables.scss";

.radio {
    :global {
        .bp3-control {
            margin-bottom: 0;
        }

        .bp3-control.bp3-inline {
            margin-right: $standard-padding/2;
            padding-left: $standard-padding/2;
        }

        .bp3-control.bp3-inline:first-of-type {
            padding-left: 0;
        }

        .bp3-control input:checked ~ .control-indicator {
            color: #ea561e;
        }
    }
}

.indicator {
    display: inline-block;
    position: relative;
    border: none;
    outline: none;
}

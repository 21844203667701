@mixin shadow-pulsation($clr) {
    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba($clr, 0.7);
        }

        70% {
            box-shadow: 0 0 0 5px rgba($clr, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba($clr, 0);
        }
    }

    box-shadow: 0 0 0 0 $clr;
    animation: pulse 2s infinite;
}

@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";

$pTop: 7px;

.stages {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
}
.blockContainer {
    width: 100%;
}
.stageListContainer {
    padding: $standard-padding;
    background-color: white;
    border-radius: $planr-border-radius;
    margin-top: $standard-padding;

    .addButton {
        margin-top: $standard-padding;
        width: 100%;
    }
}

.stageList {
    border-bottom: 1px solid $standard-planr-gray-color;

    .stageRow {
        display: flex;
        flex-direction: row;

        .number,
        .name,
        .completions,
        .sum,
        .start,
        .stop,
        .payments {
            padding: $pTop 5px;
        }

        .number {
            width: 60px;
            text-align: center;
            padding-top: $standard-padding;
        }

        .name {
            min-width: 180px;
            max-width: 180px;
            display: flex;
            flex-direction: column;
        }

        .completions {
            min-width: 185px;
            max-width: 185px;
            display: flex;
            flex-direction: column;
            align-items: center;

            :global {
                .file-link,
                .planr-dnduploader {
                    min-width: 150px;
                }

                .planr-tag {
                    display: block;
                    width: fit-content;
                    align-self: center;
                }
            }
        }

        .completions,
        .name,
        .payments {
            .buttons {
                display: flex;
                flex-direction: column;
                margin-top: auto;
            }
        }

        .sum {
            max-width: 120px;
            min-width: 120px;
            text-align: center;
        }

        .start {
            min-width: 180px;
            max-width: 180px;
            text-align: center;
        }

        .stop {
            min-width: 203px;
            max-width: 203px;
            text-align: center;
        }

        .start,
        .stop {
            .line {
                @include planr-font("small");
            }

            .line + .line {
                margin-top: $standard-padding/2;
            }
        }

        .payments {
            flex: 1 auto;
            text-align: center;
            min-width: 221px;
            max-width: 221px;
            display: flex;
            flex-direction: column;

            & > div {
                text-align: left;
                overflow: hidden;
            }

            :global {
                .payment-comment {
                    display: none;
                }

                .planr-tag {
                    display: block;
                    width: fit-content;
                    align-self: center;
                }
            }
        }

        .payments,
        .completions,
        .name {
            .buttons {
                :global {
                    .planr-button + .planr-button {
                        margin-left: 0;
                        margin-top: 5px;
                    }
                }
            }
        }

        & + .stageRow {
            border-bottom: 1px solid $standard-planr-gray-color;
        }

        &.header {
            background-color: $planr-disabled-color;
            align-items: center;
            box-shadow: 0px 2px 4px 0px rgba(34, 60, 80, 0.2);
            margin-bottom: $standard-padding/2;

            & > div {
                text-align: center;
            }

            .name {
                text-align: left;
            }
        }

        &.active {
            flex-direction: column;
            border: 1px solid $standard-planr-gray-color;
            padding-top: 5px;
            padding-bottom: 5px;
            margin-top: $standard-padding;

            .saveButton {
                margin-left: 5px;
            }

            .removeButton {
                margin-left: 5px;
                margin-right: auto;
            }
        }

        &:not(.active):not(.header) {
            .payments,
            .completions {
                @include planr-font("small");

                & > div {
                    text-align: center;
                    margin-bottom: 5px;
                }
            }

            .completions {
                :global {
                    .planr-tag {
                        margin-bottom: $standard-padding/2;
                    }
                }
            }

            .number {
                padding-top: $pTop;
            }
        }
    }
}

.linkerDialog {
    margin: 0 !important;
}

.warning {
    @include planr-font("small");
    color: $planr-brand-color;
    margin-top: 3px;
}

.paid {
    color: $planr-green-color;
}

.unpaid {
    color: $planr-red-color;
}

.linkedPayment {
    display: flex;
    flex-direction: row;
    align-items: center;

    :global {
        .planr-button {
            padding: 0;
            height: auto;
        }
    }
}
.smallExpertiseText {
    font-size: 10px;
}
.dropHere {
    border: 3px dashed transparent;
    background-color: white;
    min-height: 30px;
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px;

    &.isDraggingOver {
        border-color: $planr-brand-color;
    }

    .pointer {
        text-align: center;
        padding: $standard-padding;
        font-weight: 300;
        margin-top: auto;
    }
}

@import "modules/common/components/variables.scss";
// ::-webkit-scrollbar-thumb {
//     background: #369cd7;
// }

.mainlayout {
    display: flex;
    flex-direction: row;
    flex: 1 auto;
    overflow: hidden;
    position: relative;

    :global {
        .app-user {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
        }

        .app-version {
            margin-top: 6px;
        }
    }
}

.mainLogo {
    width: 100%;
    display: flex;
    min-height: 60px;
    overflow: hidden;
    align-items: center;
}

.mainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    overflow: hidden;
}

$sideWidth: 308px;

.leftColumn {
    width: $sideWidth;
    display: flex;
    flex-direction: column;
    z-index: 2;
    position: relative;

    .mainMenuStack {
        display: flex;
        flex: 1 auto;
        flex-direction: row;
        overflow: hidden;
    }

    :global {
        .main-menu {
            width: $sideWidth;
            padding-bottom: 0;
        }
    }
}

.preload {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.uiKitLink {
    position: fixed;
    top: 10px;
    left: 300px;
}

.bp3-popover {
    border: $standard-border-width solid $standard-gray;
}

.bp3-menu {
    color: $black;
}

.bp3-menu-item {
    border-radius: $standard-radius;

    &.removed {
        text-decoration: line-through;
    }
}

.bp3-select-popover .bp3-menu {
    max-height: 312px;
}

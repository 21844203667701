@import "modules/root/styles/planr/planr-scrolls.scss";

.tech {
    position: relative;

    :global {
        .file-versions {
            background-color: #fff;
            padding: 0 16px 16px 16px;
            flex: 1 auto;
            overflow-y: hidden;
            margin-left: 5px;
            border-radius: 4px;
            display: flex;
            flex-direction: column;

            .file-versions-scroller {
                flex: 1 auto;
                overflow-y: auto;
                flex-direction: column;
                @include planr-scoll();
            }

            .version {
                padding: 2px 0px;
                display: flex;
                flex-direction: row;
                margin-bottom: 16px;

                .employer-name span {
                    border-bottom: 1px dotted;
                }
            }
        }
    }
}

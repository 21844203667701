@import "modules/common/components/variables.scss";

.widget {
    max-height: 257px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f9f9fa;
    background-color: $standard-planr-gray-color;
    padding: 0 16px 16px 16px;
    border-radius: 4px;
    margin-top: 16px;
    .scroller {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }
    .noData {
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    :global {
        .block-header {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.widgetDialog {
    height: 800px;
    max-height: 800px;
    width: 98%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 0px;
    border-radius: 4px;
    margin-top: -52px;
    .scroller {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }
    .noData {
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    :global {
        .block-header {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.news {
    flex-direction: row;
    height: 60px;
    padding: 10px;
    min-height: 56px;
    background-color: white;
    font-family: "GothamPro", Arial, sans-serif;
    color: $standard-planr-darkblue;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 400;
    border-bottom: 2px solid $standard-planr-gray-color;

    .date {
        width: 150px;
        color: rgba(0, 39, 61, 0.4);
    }

    .title {
        width: 100%;
        word-break: break-all;
        cursor: pointer;

        .opener {
            cursor: pointer;
            margin-left: 5px;
            font-weight: normal;
        }
    }

    .editButton {
        width: 50px;
        text-align: right;
    }

    &.unread {
        font-weight: 700;
    }
}

.addButton {
    margin-left: auto;
}

.subtitleDialog {
    color: $standard-gray;
    font-size: 14px;
    font-weight: normal;
    font-family: GothamPro, Arial, sans-serif;
}

.titleDialog {
    word-break: break-all;
    font-size: 14px;
}

@import 'modules/common/components/variables.scss';
@import 'modules/root/styles/theme/blueprint/variables.scss';

.addButton {
    margin-left: $standard-padding;
    cursor: pointer;
}

.wrapper {
    display: flex;
    flex: 1 1;
    flex-direction: column;

    :global {
        strong {
            margin-bottom: $standard-padding;
        }

        .bp3-form-group {
            margin: 0;
        }
    }
}

.table {
    width: 100%;

    td {
        padding: 2px 5px 2px 0 !important;
        vertical-align: middle !important;
    }

    th {
        font-weight: normal !important;
        text-align: left !important;
    }

    :global {
        .bp3-form-helper-text {
            background-color: $white !important;
        }

        .bp3-control {
            margin: 0;
        }

        button + button {
            margin-left: 2px;
        }
    }
}

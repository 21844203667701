.page {
    margin-left: -10px;
    overflow-x: show;
    width: 100%;
    height: 100%;
    display: flex;

    padding-left: 16px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 6px;
}

@import '../variables.scss';

$w: 450px;

.assigner {
    width: $w;
}

.popover {
    width: calc(#{$w} - #{$figma-dialog-padding*2})
}

@import "modules/common/components/variables.scss";

.anchors {
    display: flex;
    flex-direction: column;
    padding: $standard-padding;

    .anchor {
        cursor: pointer;
        white-space: nowrap;

        &:hover {
            text-decoration: underline;
        }
    }
}

@import "modules/root/styles/planr/variables.scss";

.table {
    width: 100%;
    background-color: $planr-white-color;
}

.header {
    margin-bottom: 0;
    padding-bottom: 0;
    height: 25px;
}

.module {
    min-width: 270px;

    :global {
        .access-value {
            cursor: pointer;
        }
    }
}

@import "modules/common/components/variables.scss";
$date: 130px;

.page {
    flex: 60%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-size: 12px;
    color: $standard-planr-darkblue;
    margin-right: 24px;

    .panel {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 7px;
    }

    .navigtor {
        display: flex;
        margin-bottom: $standard-padding;
        .actions {
            margin-left: auto;
            text-align: right;
            .folderBtns {
                display: flex;
                flex-direction: row;
            }
        }
    }

    .sorter {
        display: flex;
        flex-direction: row;
        width: 100%;
        cursor: pointer;
        margin-bottom: 5px;
        font-size: 13px;
        margin-bottom: 16px;

        .name {
            width: 100%;
            display: flex;
        }

        .date {
            display: flex;
            width: 100px;
            text-align: right;
            padding-right: 5px;
        }
    }

    .fileLabel {
        display: flex;
        font-size: 12px;

        .date {
            width: 80px;
            margin-left: 28px;
            height: 30px;
            align-items: center;
            display: flex;
            justify-content: right;
        }

        .version {
            display: flex;
            flex-direction: row;
            width: 30px;
            cursor: pointer;
            height: 30px;
            align-items: center;
        }
    }

    .fileUploader {
        margin-left: auto;
        cursor: pointer;
        margin-top: -2px;
    }

    :global {
        .bp3-tree {
            overflow: auto;
        }
        .bp3-tree-node-content,
        .bp3-tree-node-content-2 {
            background-color: #fff;
            align-items: center;
            height: 38px;
            border-bottom: 1px solid $standard-planr-gray-color;
            &:hover {
                background-color: #edf2f5;
            }
        }

        .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
            background-color: #fdeee8;
            color: $standard-planr-darkblue;
        }

        .bp3-tree-node-secondary-label {
            width: $date;
            text-align: right;
            padding-right: 0;
        }

        .bp3-breadcrumbs {
            margin-top: 15px;
            width: 100%;
        }

        .bp3-breadcrumb {
            font-size: 14px;
            color: $standard-planr-darkblue;
            white-space: nowrap;
        }
    }
}

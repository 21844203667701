@import "modules/common/components/variables.scss";

.categoryBlock {
    .objectTitle {
        margin-bottom: 13px;
        color: $standard-planr-darkblue;
        margin-top: 24px;

        .add {
            height: 43px;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;

            & > div {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: $standard-padding;

                :global {
                    .action-icon {
                        margin-right: $standard-padding * 0.6;
                    }
                }
            }
        }
    }

    :global {
        .bp3-collapse-body {
            display: flex;
            flex-direction: column;
        }
    }
}
.dialogLabel {
    font-size: 12px;
    color: $standard-planr-darkblue;
    margin-top: 16px;
}
.FilesDialog {
    width: 730px;
}
.addFilesDialog {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
}
.addFilesDialog:first-child {
    margin-bottom: 5px;
}
.addFilesDialogColumn:first-child {
    margin-right: 16px;
}
.addFilesDialogColumn {
    flex: 50%;
}

.smallDialog {
    color: $standard-planr-darkblue;
}
